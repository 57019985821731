import React , {useEffect} from 'react';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';
import './../../css/theme/default.css';
import './../../css/app.css';
import './../../temp.js'
import ChatSideBar from './ChatSideBar';
import ChatSideContainer from "./ChatSide";
import ViewProfileModal from './ViewProfileModal';
import CreatGroup from './ChatSide/CreatGroup';
import { createGroup, sortFrnd, SetActiveChat} from "./../../action/utils.js";
import NewMessageModal from "./ChatSide/NewMessageModal.js";
// import 'bootstrap/dist/css/'

const ChatContainer = (props) => {
    let createNewGroup = (name, members) => {
        props.createGroup(name, members)
    }
    let handleSetActiveChat = (frnd, type) => {
      props.SetActiveChat(frnd, type);
      if(document.getElementById("close-chat-new-your")){
        document.getElementById("close-chat-new-your").click()
      }
    };
    if (!props.token) return <Redirect to='/' /> 
    return (
      <div className="light-default-theme">
        <div className="ca-main-conatiner">
          <div className="ca-main-wrapper">
            {/* side bar section */}
            <ChatSideBar friends={props.friends} />
            <div className="ca-content-wrapper">
              <ChatSideContainer handleSendTyping={props.handleSendTyping} friends={props.friends} frndOnChat={props.frndOnChat}/>
            </div>
            {/* <!-- Settings Modal --> */}
            <div
              className="modal settings-modal-dialog"
              id="settingsModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="settingsModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="settingsModalCenterTitle">
                      Settings
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body p-0">
                    <div className="nav-style-vertical-1">
                      <div className="container-fluid p-0">
                        <div className="row">
                          <div className="col-4 settings-nav-menu">
                            <div
                              className="nav flex-column nav-pills"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <a
                                className="nav-link active"
                                id="v-pills-privacy-tab"
                                data-toggle="pill"
                                href="#v-pills-privacy"
                                role="tab"
                                aria-controls="v-pills-privacy"
                                aria-selected="true"
                              >
                                <div className="settingmenu">
                                  <div className="settingmenu__icon">
                                    <i className="mdi mdi-shield-lock-outline"></i>
                                  </div>
                                  <div className="settingmenu__name">
                                    Privacy & Security
                                  </div>
                                </div>
                              </a>
                              <a
                                className="nav-link"
                                id="v-pills-notifications-tab"
                                data-toggle="pill"
                                href="#v-pills-notifications"
                                role="tab"
                                aria-controls="v-pills-notifications"
                                aria-selected="false"
                              >
                                <div className="settingmenu">
                                  <div className="settingmenu__icon">
                                    <i className="mdi mdi-bell-ring-outline"></i>
                                  </div>
                                  <div className="settingmenu__name">
                                    Notifications
                                  </div>
                                </div>
                              </a>
                              <a
                                className="nav-link"
                                id="v-pills-theme-tab"
                                data-toggle="pill"
                                href="#v-pills-theme"
                                role="tab"
                                aria-controls="v-pills-theme"
                                aria-selected="false"
                              >
                                <div className="settingmenu">
                                  <div className="settingmenu__icon">
                                    <i className="mdi mdi-palette-outline"></i>
                                  </div>
                                  <div className="settingmenu__name">Theme</div>
                                </div>
                              </a>
                              <a
                                className="nav-link"
                                id="v-pills-audiovideo-tab"
                                data-toggle="pill"
                                href="#v-pills-audiovideo"
                                role="tab"
                                aria-controls="v-pills-audiovideo"
                                aria-selected="false"
                              >
                                <div className="settingmenu">
                                  <div className="settingmenu__icon">
                                    <i className="mdi mdi-video-wireless-outline"></i>
                                  </div>
                                  <div className="settingmenu__name">
                                    Audio & Video
                                  </div>
                                </div>
                              </a>
                              <a
                                className="nav-link"
                                id="v-pills-media-tab"
                                data-toggle="pill"
                                href="#v-pills-media"
                                role="tab"
                                aria-controls="v-pills-media"
                                aria-selected="false"
                              >
                                <div className="settingmenu">
                                  <div className="settingmenu__icon">
                                    <i className="mdi mdi-message-settings-variant-outline"></i>
                                  </div>
                                  <div className="settingmenu__name">Media</div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="col-8 settings-nav-content">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-privacy"
                                role="tabpanel"
                                aria-labelledby="v-pills-privacy-tab"
                              >
                                <div
                                  className="accordion settings-accordion accordion-arrow-toggler accordion-ungrouped"
                                  id="privacySecurity"
                                >
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="privacySecurityHead1"
                                    >
                                      <div
                                        className="card-title"
                                        data-toggle="collapse"
                                        role="button"
                                        data-target="#privacySecurity1"
                                        aria-expanded="true"
                                        aria-controls="privacySecurity1"
                                      >
                                        Privacy
                                      </div>
                                    </div>

                                    <div
                                      id="privacySecurity1"
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#privacySecurity"
                                    >
                                      <div className="card-body">
                                        <div className="setting-sunheading">
                                          who can see my personal details
                                        </div>

                                        <ul className="list-unstyled ca-setting-list">
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Profile Picture
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select who can see your
                                                  profile picture
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="SettingsDropdown1"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Public
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="SettingsDropdown1"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Public
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      My contacts
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Selected group
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Last Seen
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select who can see my last
                                                  seen
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="SettingsDropdown2"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    My contacts
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="SettingsDropdown2"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Public
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      My contacts
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Selected group
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Online Status
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select who can see my last
                                                  seen
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="SettingsDropdown3"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Selected group
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="SettingsDropdown3"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Public
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      My contacts
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Selected group
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="privacySecurityHead2"
                                    >
                                      <div
                                        className="card-title collapsed"
                                        role="button"
                                        data-toggle="collapse"
                                        data-target="#privacySecurity2"
                                        aria-expanded="false"
                                        aria-controls="privacySecurity2"
                                      >
                                        Security
                                      </div>
                                    </div>
                                    <div
                                      id="privacySecurity2"
                                      className="collapse"
                                      aria-labelledby="headingTwo"
                                      data-parent="#privacySecurity"
                                    >
                                      <div className="card-body">
                                        <ul className="list-unstyled">
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Lock chat screen
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input type="checkbox" />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Unlock with Fingerprint
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input type="checkbox" />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Desktop notification{" "}
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input type="checkbox" />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="privacySecurityHead3"
                                    >
                                      <div
                                        className="card-title collapsed"
                                        role="button"
                                        data-toggle="collapse"
                                        data-target="#privacySecurity3"
                                        aria-expanded="false"
                                        aria-controls="privacySecurity3"
                                      >
                                        Blocked Contacts
                                      </div>
                                    </div>
                                    <div
                                      id="privacySecurity3"
                                      className="collapse"
                                      aria-labelledby="privacySecurity3"
                                      data-parent="#privacySecurity"
                                    >
                                      <div className="card-body">
                                        <ul className="list-unstyled">
                                          <li>
                                            <div className="contactlist">
                                              <div className="user-avatar user-avatar-rounded online">
                                                <img
                                                  src="./assets/images/user/500/06.jpg"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="contactlist__details">
                                                <div className="contactlist__details--name">
                                                  Chuck McCann
                                                </div>
                                                <div className="calllist__details--info">
                                                  <span>
                                                    <i className="mdi mdi-tag-outline"></i>
                                                  </span>
                                                  <span>Friends</span>
                                                </div>
                                              </div>

                                              <div className="contactlist__actions">
                                                <div
                                                  className="iconbox btn-solid-danger"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Remove"
                                                >
                                                  <i className="iconbox__icon mdi mdi-close"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="contactlist">
                                              <div className="user-avatar user-avatar-rounded online">
                                                <img
                                                  src="./assets/images/user/500/01.jpg"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="contactlist__details">
                                                <div className="contactlist__details--name">
                                                  Pearl Villarreal
                                                </div>
                                                <div className="calllist__details--info">
                                                  <span>
                                                    <i className="mdi mdi-tag-outline"></i>
                                                  </span>
                                                  <span>Friends</span>
                                                </div>
                                              </div>

                                              <div className="contactlist__actions">
                                                <div
                                                  className="iconbox btn-solid-danger"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Remove"
                                                >
                                                  <i className="iconbox__icon mdi mdi-close"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="contactlist">
                                              <div className="user-avatar user-avatar-rounded online">
                                                <img
                                                  src="./assets/images/user/500/03.jpg"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="contactlist__details">
                                                <div className="contactlist__details--name">
                                                  Sheila Hawkins
                                                </div>
                                                <div className="calllist__details--info">
                                                  <span>
                                                    <i className="mdi mdi-tag-outline"></i>
                                                  </span>
                                                  <span>Friends</span>
                                                </div>
                                              </div>

                                              <div className="contactlist__actions">
                                                <div
                                                  className="iconbox btn-solid-danger"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Remove"
                                                >
                                                  <i className="iconbox__icon mdi mdi-close"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="contactlist border-bottom-0">
                                              <div className="user-avatar user-avatar-rounded online">
                                                <img
                                                  src="./assets/images/user/500/04.jpg"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="contactlist__details">
                                                <div className="contactlist__details--name">
                                                  Amanda Sinquefield
                                                </div>
                                                <div className="calllist__details--info">
                                                  <span>
                                                    <i className="mdi mdi-tag-outline"></i>
                                                  </span>
                                                  <span>Friends</span>
                                                </div>
                                              </div>

                                              <div className="contactlist__actions">
                                                <div
                                                  className="iconbox btn-solid-danger"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Remove"
                                                >
                                                  <i className="iconbox__icon mdi mdi-close"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="v-pills-notifications"
                                role="tabpanel"
                                aria-labelledby="v-pills-notifications-tab"
                              >
                                <div className="settings-inside-wrapper">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="setting-sunheading">
                                        notification configuration
                                      </div>
                                      <ul className="list-unstyled ca-setting-list">
                                        <li>
                                          <div className="ca-setting-type">
                                            <div className="ca-setting-name">
                                              <div className="ca-setting-name-main">
                                                View Notification htmlFor
                                              </div>
                                              <div className="ca-setting-name-sub">
                                                You will get notification
                                                htmlFor
                                              </div>
                                            </div>
                                            <div className="ca-setting-action">
                                              <div className="dropdown">
                                                <a
                                                  className="btn btn-link dropdown-toggle"
                                                  href="#"
                                                  role="button"
                                                  id="notiDropdownSettings1"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  Both
                                                </a>

                                                <div
                                                  className="dropdown-menu dropdown-menu-right"
                                                  aria-labelledby="notiDropdownSettings1"
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                  >
                                                    Call only
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                  >
                                                    Message only
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                  >
                                                    Both
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="ca-setting-type">
                                            <div className="ca-setting-name">
                                              <div className="ca-setting-name-main">
                                                Notification sound htmlFor
                                              </div>
                                              <div className="ca-setting-name-sub">
                                                Notification sound play htmlFor
                                              </div>
                                            </div>
                                            <div className="ca-setting-action">
                                              <div className="dropdown">
                                                <a
                                                  className="btn btn-link dropdown-toggle"
                                                  href="#"
                                                  role="button"
                                                  id="notiDropdownSettings2"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  Message only
                                                </a>

                                                <div
                                                  className="dropdown-menu dropdown-menu-right"
                                                  aria-labelledby="notiDropdownSettings2"
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                  >
                                                    Call only
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                  >
                                                    Message only
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:;"
                                                  >
                                                    Both
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="setting-list">
                                            <div className="setting-list-name">
                                              Desktop notifications
                                            </div>
                                            <div className="setting-list-switch">
                                              <label className="material-switch">
                                                <input type="checkbox" />
                                                <span></span>
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="v-pills-theme"
                                role="tabpanel"
                                aria-labelledby="v-pills-theme-tab"
                              >
                                <div className="settings-inside-wrapper">
                                  <div className="row">
                                    <div className="col-12">
                                      <h6 className="border-bottom pb-2">
                                        Light Theme
                                      </h6>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-default-theme"
                                        className="theme default-theme selected"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-purple-theme"
                                        className="theme purple-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-pink-theme"
                                        className="theme pink-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-green-theme"
                                        className="theme green-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-red-theme"
                                        className="theme red-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-orange-theme"
                                        className="theme orange-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-blue-theme"
                                        className="theme blue-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-darkblue-theme"
                                        className="theme darkblue-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="light-lightpink-theme"
                                        className="theme lightpink-theme"
                                      ></a>

                                      <h6 className="border-bottom py-3">
                                        Dark Theme
                                      </h6>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-default-theme"
                                        className="theme default-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-purple-theme"
                                        className="theme purple-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-pink-theme"
                                        className="theme pink-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-green-theme"
                                        className="theme green-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-red-theme"
                                        className="theme red-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-orange-theme"
                                        className="theme orange-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-blue-theme"
                                        className="theme blue-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-darkblue-theme"
                                        className="theme darkblue-theme"
                                      ></a>
                                      <a
                                        href="javascript:;"
                                        data-theme="dark-lightpink-theme"
                                        className="theme lightpink-theme"
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="v-pills-audiovideo"
                                role="tabpanel"
                                aria-labelledby="v-pills-audiovideo-tab"
                              >
                                <div
                                  className="accordion settings-accordion accordion-arrow-toggler accordion-ungrouped"
                                  id="audioVideoAcc"
                                >
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="audioVideoAccHead1"
                                    >
                                      <div
                                        className="card-title"
                                        data-toggle="collapse"
                                        role="button"
                                        data-target="#audioVideoAcc1"
                                        aria-expanded="true"
                                        aria-controls="audioVideoAcc1"
                                      >
                                        Audio
                                      </div>
                                    </div>

                                    <div
                                      id="audioVideoAcc1"
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#audioVideoAcc"
                                    >
                                      <div className="card-body">
                                        <div className="setting-sunheading">
                                          Audio configurations
                                        </div>

                                        <ul className="list-unstyled ca-setting-list">
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Input
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select who can see your
                                                  profile picture
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="audioDropdownSettings1"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Microphone
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="audioDropdownSettings1"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Default Device{" "}
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Microphone
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Stereo mix
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="audioVideoAccHead2"
                                    >
                                      <div
                                        className="card-title collapsed"
                                        role="button"
                                        data-toggle="collapse"
                                        data-target="#audioVideoAcc2"
                                        aria-expanded="false"
                                        aria-controls="audioVideoAcc2"
                                      >
                                        Video
                                      </div>
                                    </div>
                                    <div
                                      id="audioVideoAcc2"
                                      className="collapse"
                                      aria-labelledby="headingTwo"
                                      data-parent="#audioVideoAcc"
                                    >
                                      <div className="card-body">
                                        <div className="setting-sunheading">
                                          Video configurations
                                        </div>

                                        <ul className="list-unstyled ca-setting-list">
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Input
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select who can see your
                                                  profile picture
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="audioDropdownSettings2"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    UHD webcam
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="audioDropdownSettings2"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Default webcam{" "}
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      HD webcam{" "}
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      UHD webcam{" "}
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="v-pills-media"
                                role="tabpanel"
                                aria-labelledby="v-pills-media-tab"
                              >
                                <div
                                  className="accordion settings-accordion accordion-arrow-toggler accordion-ungrouped"
                                  id="mediaAcc"
                                >
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="mediaAccHead1"
                                    >
                                      <div
                                        className="card-title"
                                        data-toggle="collapse"
                                        role="button"
                                        data-target="#mediaAcc1"
                                        aria-expanded="true"
                                        aria-controls="mediaAcc1"
                                      >
                                        Backups
                                      </div>
                                    </div>

                                    <div
                                      id="mediaAcc1"
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#mediaAcc"
                                    >
                                      <div className="card-body">
                                        <div className="setting-sunheading">
                                          Backups configurations
                                        </div>

                                        <ul className="list-unstyled ca-setting-list">
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Backup Type
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select that how you want to
                                                  take backup
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="backupDropdownSettings1"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Auto
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="backupDropdownSettings1"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Auto
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Manual
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Backup Schedule
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Select span of the backup time
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="backupDropdownSettings2"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Every Day
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="backupDropdownSettings2"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Every Day
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Every Week
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Every Month
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Delete Old Media
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Delete media automatically
                                                  after period of time
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="backupDropdownSettings3"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Never
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="backupDropdownSettings3"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Never
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Every Week
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Every Month
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Every Year
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <div className="setting-sunheading mt-5 mb-3">
                                          Backups items
                                        </div>

                                        <ul className="list-unstyled">
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Chat
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input
                                                    type="checkbox"
                                                    // checked=""
                                                  />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Images{" "}
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input
                                                    type="checkbox"
                                                    // checked=""
                                                  />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Videos{" "}
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input type="checkbox" />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Documents
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input
                                                    type="checkbox"
                                                    // checked=""
                                                  />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id="mediaAccHead2"
                                    >
                                      <div
                                        className="card-title collapsed"
                                        role="button"
                                        data-toggle="collapse"
                                        data-target="#mediaAcc2"
                                        aria-expanded="false"
                                        aria-controls="mediaAcc2"
                                      >
                                        Media Settings
                                      </div>
                                    </div>
                                    <div
                                      id="mediaAcc2"
                                      className="collapse"
                                      aria-labelledby="headingTwo"
                                      data-parent="#mediaAcc"
                                    >
                                      <div className="card-body">
                                        <div className="setting-sunheading mb-3">
                                          Auto Download Media
                                        </div>

                                        <ul className="list-unstyled">
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Images{" "}
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input
                                                    type="checkbox"
                                                    // checked=""
                                                  />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Videos{" "}
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input type="checkbox" />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="setting-list">
                                              <div className="setting-list-name">
                                                Documents
                                              </div>
                                              <div className="setting-list-switch">
                                                <label className="material-switch">
                                                  <input
                                                    type="checkbox"
                                                    // checked=""
                                                  />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>

                                        <div className="setting-sunheading mt-5 mb-3">
                                          Media configuration
                                        </div>

                                        <ul className="list-unstyled ca-setting-list">
                                          <li>
                                            <div className="ca-setting-type">
                                              <div className="ca-setting-name">
                                                <div className="ca-setting-name-main">
                                                  Image Quality
                                                </div>
                                                <div className="ca-setting-name-sub">
                                                  Choose media quality you want
                                                  to send
                                                </div>
                                              </div>
                                              <div className="ca-setting-action">
                                                <div className="dropdown">
                                                  <a
                                                    className="btn btn-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="mediaDropdownSettings1"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    Original
                                                  </a>

                                                  <div
                                                    className="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="mediaDropdownSettings1"
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Good
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Excellent
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:;"
                                                    >
                                                      Original
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="calling-modal-data">
              {/* <!-- VIDEO CALL START--> */}
              <div
                className="modal fade videocall-modal CallDialogFullscreen-sm"
                id="incomingVideoStart"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="incomingVideoStart"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl "
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="icvideocallwrapper">
                        <div className="icvideo-contact">
                          <img
                            className="img-fluid icvideo-contact__inner"
                            src="./assets/images/media/call-01.jpg"
                            alt=""
                          />
                        </div>
                        <div className="icvideo-user">
                          <img
                            className=""
                            src="./assets/images/media/call-02.jpg"
                            alt=""
                          />
                        </div>
                        <div className="icvideo-actions">
                          <div className="icvideo-actions__left">
                            <div className="icvideo-actions__left--duration">
                              00:36
                            </div>
                          </div>
                          <div className="icvideo-actions__middle">
                            <div className="">
                              <div
                                className="iconbox btn-hovered-light"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Speaker"
                              >
                                <i className="iconbox__icon mdi mdi-volume-high"></i>
                              </div>
                              <div
                                className="iconbox btn-hovered-light"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Hold"
                              >
                                <i className="iconbox__icon mdi mdi-pause"></i>
                              </div>
                              <div
                                className="iconbox btn-hovered-light"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add Call"
                              >
                                <i className="iconbox__icon mdi mdi-phone-plus"></i>
                              </div>
                              <div
                                className="iconbox btn-hovered-light"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Disbale Video"
                              >
                                <i className="iconbox__icon mdi mdi-video-off-outline"></i>
                              </div>
                              <div
                                className="iconbox btn-hovered-light"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mute"
                              >
                                <i className="iconbox__icon mdi mdi-microphone-off"></i>
                              </div>
                            </div>
                          </div>
                          <div className="icvideo-actions__right">
                            <div
                              className="iconbox btn-hovered-light bg-danger"
                              data-dismiss="modal"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Hangup"
                            >
                              <i className="iconbox__icon text-white mdi mdi-phone-hangup"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- OUTGOING VOICE CALL --> */}
              <div
                className="modal CallDialogFullscreen-sm"
                id="outGoingCall"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="outGoingCall"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="text-center">
                        <div className="user-avatar user-avatar-rounded user-avatar-xl">
                          <img src="./assets/images/user/250/01.jpg" alt="" />
                        </div>
                        <div className="userprofile-name">
                          <span>Calling...</span>
                          <h4>Jack P. Angulo</h4>
                          <span>Product Manager</span>

                          <div className="call-duration">00:36</div>
                        </div>

                        <div className="call-options">
                          <div className="row">
                            <div className="col-4">
                              <div className="call-options__iconbox">
                                <i className="mdi mdi-microphone-off"></i>
                              </div>
                              <h6>Mute</h6>
                            </div>
                            <div className="col-4">
                              <div className="call-options__iconbox">
                                <i className="mdi mdi-volume-high"></i>
                              </div>
                              <h6>Speaker</h6>
                            </div>
                            <div className="col-4">
                              <div className="call-options__iconbox">
                                <i className="mdi mdi-pause"></i>
                              </div>
                              <h6>Hold</h6>
                            </div>
                          </div>
                        </div>

                        <div className="call-actions">
                          <div className="call-hangup" data-dismiss="modal">
                            <i className="mdi mdi-phone-hangup"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-msgcall-data">
              {/* <!-- NEW MESSAGE MODAL --> */}
                <NewMessageModal friends={props.friends} handleSetActiveChat={handleSetActiveChat}></NewMessageModal>
              {/* <!-- NEW CALL MODAL --> */}
              <div
                className="modal new-call-dialog"
                id="newCallModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="newCallModal"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title" id="newCallModalLabel">
                        Create New Call
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="searchbar">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            aria-label="Search"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text dialpad-opener">
                              <i className="mdi mdi-dialpad"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="modal-contact-list">
                        <ul className="list-unstyled">
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/06.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Chuck McCann
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Pearl Villarreal
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Sheila Hawkins
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist border-bottom-0">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-success">
                                  <i className="iconbox__icon mdi mdi-phone"></i>
                                </div>
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="modal-dialpad">
                        <div className="dial-pad-wrap">
                          <div className="dial-pad">
                            <div className="dial-screen">+01-(363)-2612</div>
                            <div className="dial-table">
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="1">
                                    <div className="dial-key">1</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="2">
                                    <div className="dial-key">2</div>
                                    <div className="dial-sub-key">abc</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="3">
                                    <div className="dial-key">3</div>
                                    <div className="dial-sub-key">def</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="4">
                                    <div className="dial-key">4</div>
                                    <div className="dial-sub-key">ghi</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="5">
                                    <div className="dial-key">5</div>
                                    <div className="dial-sub-key">jkl</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="6">
                                    <div className="dial-key">6</div>
                                    <div className="dial-sub-key">mno</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="7">
                                    <div className="dial-key">7</div>
                                    <div className="dial-sub-key">pqrs</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="8">
                                    <div className="dial-key">8</div>
                                    <div className="dial-sub-key">tuv</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="9">
                                    <div className="dial-key">9</div>
                                    <div className="dial-sub-key">wxyz</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="*">
                                    <div className="dial-key">*</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="0">
                                    <div className="dial-key">0</div>
                                    <div className="dial-sub-key">+</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="#">
                                    <div className="dial-key">#</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row no-sub-key">
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="save-contact"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-account-plus-outline"></i>
                                    </div>
                                    <div className="dial-sub-key">Call</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="call"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-phone"></i>
                                    </div>
                                    <div className="dial-sub-key">Call</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="back"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-arrow-left"></i>
                                    </div>
                                    <div className="dial-sub-key">Back</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- NEW GROUP MODAL --> */}
              <CreatGroup friends={props.friends} />
              {/* <!-- ADD MEMBER IN GROUP MODAL --> */}
              <div
                className="modal new-group-dialog"
                id="addMemberModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addMemberModal"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title" id="addMemberModalLabel">
                        Add Members
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="searchbar">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            aria-label="Search"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text dialpad-opener">
                              <i className="mdi mdi-dialpad"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="modal-contact-list">
                        <ul className="list-unstyled">
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/06.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Chuck McCann
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Pearl Villarreal
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Sheila Hawkins
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist border-bottom-0">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="modal-dialpad">
                        <div className="dial-pad-wrap">
                          <div className="dial-pad">
                            <div className="dial-screen">+01-(363)-2612</div>
                            <div className="dial-table">
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="1">
                                    <div className="dial-key">1</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="2">
                                    <div className="dial-key">2</div>
                                    <div className="dial-sub-key">abc</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="3">
                                    <div className="dial-key">3</div>
                                    <div className="dial-sub-key">def</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="4">
                                    <div className="dial-key">4</div>
                                    <div className="dial-sub-key">ghi</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="5">
                                    <div className="dial-key">5</div>
                                    <div className="dial-sub-key">jkl</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="6">
                                    <div className="dial-key">6</div>
                                    <div className="dial-sub-key">mno</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="7">
                                    <div className="dial-key">7</div>
                                    <div className="dial-sub-key">pqrs</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="8">
                                    <div className="dial-key">8</div>
                                    <div className="dial-sub-key">tuv</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="9">
                                    <div className="dial-key">9</div>
                                    <div className="dial-sub-key">wxyz</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="*">
                                    <div className="dial-key">*</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="0">
                                    <div className="dial-key">0</div>
                                    <div className="dial-sub-key">+</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="#">
                                    <div className="dial-key">#</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row no-sub-key">
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="save-contact"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-account-plus-outline"></i>
                                    </div>
                                    <div className="dial-sub-key">Call</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="call"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-plus-circle-outline"></i>
                                    </div>
                                    <div className="dial-sub-key">Call</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="back"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-arrow-left"></i>
                                    </div>
                                    <div className="dial-sub-key">Back</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- INVITE PEOPLE --> */}
              <div
                className="modal new-group-dialog"
                id="invitePeopleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="invitePeopleModal"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title" id="invitePeopleModalLabel">
                        Invite Members
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="searchbar">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            aria-label="Search"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text dialpad-opener">
                              <i className="mdi mdi-dialpad"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="modal-contact-list">
                        <ul className="list-unstyled">
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/06.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Chuck McCann
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Pearl Villarreal
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Sheila Hawkins
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="contactlist border-bottom-0">
                              <div className="user-avatar user-avatar-rounded">
                                <img
                                  src="./assets/images/user/500/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="contactlist__details">
                                <div className="contactlist__details--name">
                                  Amanda Sinquefield
                                </div>
                                <div className="calllist__details--info">
                                  <span>
                                    <i className="mdi mdi-tag-outline"></i>
                                  </span>
                                  <span>Friends</span>
                                </div>
                              </div>

                              <div className="contactlist__actions">
                                <div className="iconbox btn-solid-info">
                                  <i className="iconbox__icon mdi mdi-plus"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="modal-dialpad">
                        <div className="dial-pad-wrap">
                          <div className="dial-pad">
                            <div className="dial-screen">+01-(363)-2612</div>
                            <div className="dial-table">
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="1">
                                    <div className="dial-key">1</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="2">
                                    <div className="dial-key">2</div>
                                    <div className="dial-sub-key">abc</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="3">
                                    <div className="dial-key">3</div>
                                    <div className="dial-sub-key">def</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="4">
                                    <div className="dial-key">4</div>
                                    <div className="dial-sub-key">ghi</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="5">
                                    <div className="dial-key">5</div>
                                    <div className="dial-sub-key">jkl</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="6">
                                    <div className="dial-key">6</div>
                                    <div className="dial-sub-key">mno</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="7">
                                    <div className="dial-key">7</div>
                                    <div className="dial-sub-key">pqrs</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="8">
                                    <div className="dial-key">8</div>
                                    <div className="dial-sub-key">tuv</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="9">
                                    <div className="dial-key">9</div>
                                    <div className="dial-sub-key">wxyz</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row">
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="*">
                                    <div className="dial-key">*</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="0">
                                    <div className="dial-key">0</div>
                                    <div className="dial-sub-key">+</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div className="dial-key-wrap" data-key="#">
                                    <div className="dial-key">#</div>
                                    <div className="dial-sub-key">&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                              <div className="dial-table-row no-sub-key">
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="save-contact"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-account-plus-outline"></i>
                                    </div>
                                    <div className="dial-sub-key">Call</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="call"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-plus-circle-outline"></i>
                                    </div>
                                    <div className="dial-sub-key">Call</div>
                                  </div>
                                </div>
                                <div className="dial-table-col">
                                  <div
                                    className="dial-key-wrap"
                                    data-key="back"
                                  >
                                    <div className="dial-key">
                                      <i className="mdi mdi-arrow-left"></i>
                                    </div>
                                    <div className="dial-sub-key">Back</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- VIEW PROFILE MODAL --> */}
            <ViewProfileModal user={props.user} />

            {/* <!-- THEME MODAL --> */}
            <div
              className="modal new-group-dialog"
              id="themeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="themeModal"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h6 className="modal-title" id="themeModalLabel">
                      Theme
                    </h6>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <h6 className="border-bottom pb-2">Light Theme</h6>
                    <div className="d-flex flex-wrap">
                      <a
                        href="javascript:;"
                        data-theme="light-default-theme"
                        className="theme default-theme selected"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-purple-theme"
                        className="theme purple-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-pink-theme"
                        className="theme pink-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-green-theme"
                        className="theme green-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-red-theme"
                        className="theme red-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-orange-theme"
                        className="theme orange-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-blue-theme"
                        className="theme blue-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-darkblue-theme"
                        className="theme darkblue-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="light-lightpink-theme"
                        className="theme lightpink-theme"
                      ></a>
                    </div>
                    <h6 className="border-bottom py-3">Dark Theme</h6>
                    <div className="d-flex flex-wrap">
                      <a
                        href="javascript:;"
                        data-theme="dark-default-theme"
                        className="theme default-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-purple-theme"
                        className="theme purple-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-pink-theme"
                        className="theme pink-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-green-theme"
                        className="theme green-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-red-theme"
                        className="theme red-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-orange-theme"
                        className="theme orange-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-blue-theme"
                        className="theme blue-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-darkblue-theme"
                        className="theme darkblue-theme"
                      ></a>
                      <a
                        href="javascript:;"
                        data-theme="dark-lightpink-theme"
                        className="theme lightpink-theme"
                      ></a>
                    </div>

                    <h6 className="border-bottom py-3">RTL Layout</h6>
                    <label className="material-switch">
                      <input type="checkbox" className="rtlSwitch" />
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- THEME CUSTOMIZER --> */}
            <div className="theme-customizer">
              <div className="theme-customizer-opener">
                <i className="theme-customizer-icon mdi mdi-settings"></i>
              </div>

              <div className="theme-customizer-content">
                <h6 className="border-bottom pb-2">Light Theme</h6>
                <div className="d-flex flex-wrap">
                  <a
                    href="javascript:;"
                    data-theme="light-default-theme"
                    className="theme default-theme selected"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-purple-theme"
                    className="theme purple-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-pink-theme"
                    className="theme pink-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-green-theme"
                    className="theme green-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-red-theme"
                    className="theme red-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-orange-theme"
                    className="theme orange-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-blue-theme"
                    className="theme blue-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-darkblue-theme"
                    className="theme darkblue-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="light-lightpink-theme"
                    className="theme lightpink-theme"
                  ></a>
                </div>
                <h6 className="border-bottom py-3">Dark Theme</h6>
                <div className="d-flex flex-wrap">
                  <a
                    href="javascript:;"
                    data-theme="dark-default-theme"
                    className="theme default-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-purple-theme"
                    className="theme purple-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-pink-theme"
                    className="theme pink-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-green-theme"
                    className="theme green-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-red-theme"
                    className="theme red-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-orange-theme"
                    className="theme orange-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-blue-theme"
                    className="theme blue-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-darkblue-theme"
                    className="theme darkblue-theme"
                  ></a>
                  <a
                    href="javascript:;"
                    data-theme="dark-lightpink-theme"
                    className="theme lightpink-theme"
                  ></a>
                </div>

                <h6 className="border-bottom py-3">RTL Layout</h6>
                <label className="material-switch">
                  <input type="checkbox" className="rtlSwitch " />
                  <span></span>
                </label>
              </div>
            </div>

            <div className="backdrop-overlay hidden"></div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  let a = []
  if(state.friends.friends){
    a = sortFrnd(Object.values(state.friends.friends));
  }
  return {
    user: state.user.user,
    token: state.user.token,
    friends:  [...a],
}}

const mapDispatchToProps = {
  createGroup,
  SetActiveChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer)
