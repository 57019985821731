import React, {useState, useEffect} from 'react'

export default function ContactList(props) {
    let [alphet, setAlphet] = useState(['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'])
    useEffect(()=>{
        console.log("opppppppppp", props.friendsObj);
    },[])
    return (
    <div className="tab-pane fade position-relative" id="caContacts" role="tabpanel" aria-labelledby="caContactsTab">
        <div className="sidebar-contactlist">
            <ul className="list-unstyled userSearchList">
                <li>
                    <div className="contactlist-heading">
                        <span>Favourite</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/01.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Jack P. Angulo</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist active">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/500/09.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Pearl Villarreal</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Family members</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/500/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Marguerite E. Hutchings</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Friends</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/500/06.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Chuck McCann</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Friends</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>A</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Andrew Showalter</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Amanda Sinquefield</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>B</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Andrew Showalter</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Amanda Sinquefield</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>C</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Andrew Showalter</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Amanda Sinquefield</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>E</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Andrew Showalter</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Amanda Sinquefield</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>M</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Andrew Showalter</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Amanda Sinquefield</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>S</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Andrew Showalter</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">Amanda Sinquefield</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist-heading">
                        <span>#</span>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">&#9829; My Love &#9829;</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded online">
                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                        </div>
                        <div className="contactlist__details">
                            <div className="contactlist__details--name">&#126; Eva 	&#126;</div>
                            <div className="calllist__details--info">
                                <span><i className="mdi mdi-tag-outline"></i></span>
                                <span>Co-Workers</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="alphabet-series">
            <span><i className="mdi mdi-star"></i></span>
            <span>A</span>
            <span>B</span>
            <span>D</span>
            <span>E</span>
            <span>F</span>
            <span>G</span>
            <span>H</span>
            <span>I</span>
            <span>J</span>
            <span>K</span>
            <span>L</span>
            <span>M</span>
            <span>N</span>
            <span>O</span>
            <span>P</span>
            <span>Q</span>
            <span>R</span>
            <span>S</span>
            <span>T</span>
            <span>U</span>
            <span>V</span>
            <span>W</span>
            <span>X</span>
            <span>Y</span>
            <span>Z</span>
            <span>#</span>
        </div>
    </div>
    )
}
