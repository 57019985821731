import React, {useEffect, useState} from 'react'

export default function NewMessageModal(props) {
    let [friends, setFriends] = useState(props.friends)
    let [text, setText] = useState('')
    let handleFilter = (e) =>{
        let arr = [...props.friends]
        let newArr = arr.filter((frnd)=>{
            let str = `${frnd.fname} ${frnd.lname}`.toLowerCase()
            if(str.indexOf(e.target.value.toLowerCase())!== -1){
                return frnd
            }
        })
        setFriends(newArr);
        setText(e.target.value)
    }
    useEffect(()=>{
        setFriends(props.friends)
    }, [props.friends])
    return (
      <div
        className="modal new-message-dialog"
        id="newMsgModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="newMsgModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="newMsgModalLabel">
                Create New Message
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close-chat-new-your"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="searchbar">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={handleFilter}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text dialpad-opener">
                      <i className="mdi mdi-dialpad"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-contact-list">
                <ul className="list-unstyled">
                  {friends.map((data) => (
                    <li key={data.id}>
                      <div className="contactlist">
                        <div className="user-avatar user-avatar-rounded">
                          <img src="./assets/images/user/500/06.jpg" alt="" />
                        </div>
                        <div className="contactlist__details">
                          <div className="contactlist__details--name">
                            {`${data.fname} ${data.lname}`}
                          </div>
                          <div className="calllist__details--info">
                            <span>
                              <i className="mdi mdi-tag-outline"></i>
                            </span>
                            <span>Friends</span>
                          </div>
                        </div>

                        <div className="contactlist__actions">
                          <div className="iconbox btn-solid-primary" onClick={()=>{props.handleSetActiveChat(data, 1)}}>
                            <i className="iconbox__icon mdi mdi-message-text-outline"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="modal-dialpad">
                <div className="dial-pad-wrap">
                  <div className="dial-pad">
                    <div className="dial-screen">+01-(363)-2612</div>
                    <div className="dial-table">
                      <div className="dial-table-row">
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="1">
                            <div className="dial-key">1</div>
                            <div className="dial-sub-key">&nbsp;</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="2">
                            <div className="dial-key">2</div>
                            <div className="dial-sub-key">abc</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="3">
                            <div className="dial-key">3</div>
                            <div className="dial-sub-key">def</div>
                          </div>
                        </div>
                      </div>
                      <div className="dial-table-row">
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="4">
                            <div className="dial-key">4</div>
                            <div className="dial-sub-key">ghi</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="5">
                            <div className="dial-key">5</div>
                            <div className="dial-sub-key">jkl</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="6">
                            <div className="dial-key">6</div>
                            <div className="dial-sub-key">mno</div>
                          </div>
                        </div>
                      </div>
                      <div className="dial-table-row">
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="7">
                            <div className="dial-key">7</div>
                            <div className="dial-sub-key">pqrs</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="8">
                            <div className="dial-key">8</div>
                            <div className="dial-sub-key">tuv</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="9">
                            <div className="dial-key">9</div>
                            <div className="dial-sub-key">wxyz</div>
                          </div>
                        </div>
                      </div>
                      <div className="dial-table-row">
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="*">
                            <div className="dial-key">*</div>
                            <div className="dial-sub-key">&nbsp;</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="0">
                            <div className="dial-key">0</div>
                            <div className="dial-sub-key">+</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="#">
                            <div className="dial-key">#</div>
                            <div className="dial-sub-key">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                      <div className="dial-table-row no-sub-key">
                        <div className="dial-table-col">
                          <div
                            className="dial-key-wrap"
                            data-key="save-contact"
                          >
                            <div className="dial-key">
                              <i className="mdi mdi-account-plus-outline"></i>
                            </div>
                            <div className="dial-sub-key">Call</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="call">
                            <div className="dial-key">
                              <i className="mdi mdi-message-text-outline"></i>
                            </div>
                            <div className="dial-sub-key">Call</div>
                          </div>
                        </div>
                        <div className="dial-table-col">
                          <div className="dial-key-wrap" data-key="back">
                            <div className="dial-key">
                              <i className="mdi mdi-arrow-left"></i>
                            </div>
                            <div className="dial-sub-key">Back</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
