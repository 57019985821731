import axios from 'axios'
import {USER_LOGIN} from './../types'

const clearLocalStorage = ()=>{
  // localStorage.clear();
  // return window.location.reload();
}

export const Logout = () => {
    return () => {
        if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token ) {
          let token = JSON.parse(localStorage.getItem("user")).token;
          let headers = {
            Authorization: `Bearer ${token}`,
          };
          axios({
            method: "post",
            headers,
            url: `/api/chat/logout`,
          }).then((response) => {
            if (response.data.success === true) {
              localStorage.clear();
              return window.location.reload();
            }
          }).catch((err)=>{
            // if(err && err.status === 403){
              clearLocalStorage();
            // }
          })
        }
    }
}

export const LoginUser  = (credencials) => {
    return (dispatch) => {
        let headers = {
          "Content-Type": "application/json",
          "Authorization": '' ,
        };
        axios({
          headers,
          data: ((typeof credencials) === 'string')? {u_t: credencials} : credencials,
          method: "post",
          url: "/api/chat/login",
        }).then(res => {
            if(document.getElementById("login-loader")){
              document.getElementById("login-loader").style.display = "none";
            }

            if(res.data.success === 'true'){
              localStorage.setItem('user',JSON.stringify(res.data.message));
              // window.location.replace("http://localhost:3000/chat");
              window.location.replace("/chat");

              return dispatch({
                type: USER_LOGIN,
                payload: res.data.message
              });
            } else {
              console.log(res.data);
              alert('Invalid Credentials');
            }
        }).catch((err)=>{
          if (document.getElementById("login-loader")) {
            document.getElementById("login-loader").style.display = "none";
          }
          if(err && err.status === 403){
            clearLocalStorage();
          }else{
            // alert("system Error")
          }
        })
    }
}

export const MySetSate = (type, payload) => {
    return (dispatch) => {
        return dispatch ({
            type, payload
        })
    }
}
