import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'jquery';

import 'popper.js';
import 'bootstrap';
import'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import store from './store.js';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
import { SocketContext, socket } from "./pages/socket";

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <SocketContext.Provider value={socket}>
          <App />
        </SocketContext.Provider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
