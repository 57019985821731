import { USER_LOGIN, SET_USER } from "./../types.js";

let initialState = {
  user: null,
  token: null,
  socket_url: "https://io.icubicle.net/",
  // socket_url: "http://localhost:4000/",
  sk_token: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).sk_token
    : "",
};

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case USER_LOGIN:
        let {token} = payload
        return { ...state, user: null, token};
      case SET_USER:
        return {...state, user: payload}
      default:
        return state;
    }
}

export default authReducer;
