import React, {useState, useEffect} from 'react';
import {createGroup} from './../../../action/utils';
import {connect} from 'react-redux';
// import Loader from "./../../../assets/images/"

function CreatGroup(props) {
    let [groupName, setGroupName] = useState('');
    let [groupMembers, setGroupMembers] = useState({});
    let [friends, setFriends] = useState([])
    let handleChange = (e) => {
        e.preventDefault();
        setGroupName(e.target.value);
    }
    useEffect(()=>{
        setFriends([...props.friends])
    },[props.friends])

    let handleGroupMembers = (frnd) => {
        if (!groupMembers[frnd.id]) {
            groupMembers[frnd.id] = {
                token: frnd.token,
                fname: frnd.fname,
                lname: frnd.lname,
                id: frnd.id
            }
        }else {
            delete groupMembers[frnd.id];
        }
        setGroupMembers({...groupMembers})
    }

    let createNewGroup = (name, members) => {
        props.createGroup(name, members)
    }

    let handleFilter = (e) => {
        e.preventDefault()
        if(e.target.value.length){
            let newFriends = props.friends.filter((friend, index)=>{
                let name = friend.fname +" " + friend.lname
                if (name.toLowerCase().indexOf(e.target.value.toLowerCase().trim()) !== -1) {
                    return friend
                }
            })
            return setFriends(newFriends)
        }
        return setFriends([...props.friends])
    }
    // useState(()=>{}, [groupMembers])
    return (

                <div className="modal new-group-dialog"  id="newGroupModal" tabIndex="-1" role="dialog" aria-labelledby="newGroupModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="newGroupModalLabel">Create New Group</h6>
                                <button type="button" className="close" id="close-my-create-new-group" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                            <label htmlFor="defaultBaseInput" className="font-weight-bold">Group Name</label>
                                        <input className="form-control" onChange={handleChange} value={groupName}  type="text" id="defaultBaseInput"/>
                                    </div>
                                </form>
                                <div className="group-members">
                                    <div className="group-members__title">
                                        <h6>Group Members</h6>
                                        <span>{Object.values(groupMembers).length}/100</span>
                                    </div>
                                    <div className="group-members__avatar">
                                        {
                                            Object.values(groupMembers).map(( member, index )=>{
                                                if (index < 8) {
                                                    return (
                                                        <div className="user-avatar user-avatar-rounded border" key={member.lname + member.fname + index }>
                                                            {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                                                            <span className=''>{`${member.fname[0].toUpperCase()} ${member.lname[0].toUpperCase()}`}</span>
                                                            <div className="user-avatar-closer" onClick={()=>{
                                                                handleGroupMembers(member);
                                                            }}><i className="mdi mdi-close"></i></div>
                                                        </div>
                                                    )

                                                }
                                            })
                                        }
                                        {/* <div className="user-avatar user-avatar-rounded">
                                            <img src="./assets/images/user/250/02.jpg" alt=""/>
                                            <div className="user-avatar-closer"><i className="mdi mdi-close"></i></div>
                                        </div> */}
                                    </div>
                                </div>
                                <hr/>
                            
                                <div className="searchbar">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search" onChange={handleFilter} aria-label="Search"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text dialpad-opener"><i className="mdi mdi-dialpad"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-contact-list">
                                    <ul className="list-unstyled">
                                        {
                                            friends.map((frnd, index)=>{
                                                return(   <li key={frnd.fname + frnd.lname + index}>
                                                    <div className="contactlist">
                                                        <div className="user-avatar user-avatar-rounded" style={{display: 'flex', justifyContent: 'center',alignItems:'center', height:'50px', width:'50px'}}>
                                                            {/* <img src="./assets/images/user/500/06.jpg" alt=""/> */}
                                                            <h2 className='h6'>{frnd.fname && frnd.fname[0].toUpperCase()} {frnd.lname && frnd.lname[0].toUpperCase()}</h2>
                                                        </div>
                                                        <div className="contactlist__details">
                                                <div className="contactlist__details--name">{frnd.fname} {frnd.lname}</div>
                                                            <div className="calllist__details--info">
                                                                <span><i className="mdi mdi-tag-outline"></i></span>
                                                                <span>Friends</span>
                                                            </div>
                                                        </div>

                                                        <div className="contactlist__actions">
                                                            <div className={groupMembers[frnd.id]? 'iconbox btn-solid-success':'iconbox btn-solid-info'} onClick={(e) => {
                                                                handleGroupMembers(frnd);
                                                            }}>
                                                                <i className={groupMembers[frnd.id]? "iconbox__icon mdi mdi-check": "iconbox__icon mdi mdi-plus"}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>)

                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="modal-dialpad">
                                    <div className="dial-pad-wrap">
                                        <div className="dial-pad">
                                            <div className="dial-screen">+01-(363)-2612</div>
                                            <div className="dial-table">
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="1">
                                                        <div className="dial-key">1</div>
                                                        <div className="dial-sub-key">&nbsp;</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="2">
                                                        <div className="dial-key">2</div>
                                                        <div className="dial-sub-key">abc</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="3">
                                                        <div className="dial-key">3</div>
                                                        <div className="dial-sub-key">def</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="4">
                                                        <div className="dial-key">4</div>
                                                        <div className="dial-sub-key">ghi</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="5">
                                                        <div className="dial-key">5</div>
                                                        <div className="dial-sub-key">jkl</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="6">
                                                        <div className="dial-key">6</div>
                                                        <div className="dial-sub-key">mno</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="7">
                                                        <div className="dial-key">7</div>
                                                        <div className="dial-sub-key">pqrs</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="8">
                                                        <div className="dial-key">8</div>
                                                        <div className="dial-sub-key">tuv</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="9">
                                                        <div className="dial-key">9</div>
                                                        <div className="dial-sub-key">wxyz</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="*">
                                                        <div className="dial-key">*</div>
                                                        <div className="dial-sub-key">&nbsp;</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="0">
                                                        <div className="dial-key">0</div>
                                                        <div className="dial-sub-key">+</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="#">
                                                        <div className="dial-key">#</div>
                                                        <div className="dial-sub-key">&nbsp;</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row no-sub-key">
                                                    <div className="dial-table-col">
                                                            
                                                        <div className="dial-key-wrap" data-key="save-contact">
                                                            <div className="dial-key"><i className="mdi mdi-account-plus-outline"></i></div>
                                                            <div className="dial-sub-key">Call</div>
                                                        </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="call">
                                                        <div className="dial-key"><i className="mdi mdi-plus-circle-outline"></i></div>
                                                        <div className="dial-sub-key">Call</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="back">
                                                        <div className="dial-key"><i className="mdi mdi-arrow-left"></i></div>
                                                        <div className="dial-sub-key">Back</div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                            <button className='btn btn-danger mt-3 ml-auto' onClick={(e)=>{ e.preventDefault(); createNewGroup(groupName, groupMembers)}}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
    )
}


export default connect(null , {createGroup})(CreatGroup)