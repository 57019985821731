import React,{useEffect} from 'react';
import {connect} from 'react-redux';

import dTick from "./../../../assets/images/tick/tick-delivered.svg";
import readTick from "./../../../assets/images/tick/tick-read.svg";
import sendTick from "./../../../assets/images/tick/tick-send.svg";


function ChatList(props) {
   
    let selectFrnd = async (frnd) => {
      await props.handleSelectFrnd(frnd, 1);
    };
        useEffect(() => {
         let pChatTab = document.getElementById("personal-chat-tab");
         let gChatTab = document.getElementById("groups-chat-tab");
         if (gChatTab) {
             gChatTab.addEventListener("click", () => {
                if (document.getElementById("my-personal-chat")) {
                    document.getElementById("my-personal-chat").style.display = "block";
                 }
             });
         }

         if (pChatTab) {
             pChatTab.addEventListener("click", () => {
                if (document.getElementById("my-personal-chat")) {
                    document.getElementById("my-personal-chat").style.display = "block";
                }
             });
         }

    }, [])
    let clearUnreadChat = (frndToken) => {
        let unReadSpan = document.getElementById('new-direct-message-'+ frndToken)
        if (unReadSpan) {
            unReadSpan.innerHTML = ''
        }
    }
    return (
        <div className="tab-pane fade show active" id="caChats" role="tabpanel" aria-labelledby="caChatsTab">
            <div className="nav-style-2">
                <ul className="nav nav-pills py-3" id="caChatsTabInside" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link d-flex flex-row align-items-center active" id="personal-chat-tab" data-toggle="pill" href="#personal-chat" role="tab" aria-controls="personal-chat" aria-selected="true"> 
                            <span>Personal</span>
                            <span className="badge badge-primary badge-rounded badge-font-size ml-2">2</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link  d-flex flex-row align-items-center" id="groups-chat-tab" data-toggle="pill" href="#groups-chat" role="tab" aria-controls="groups-chat" aria-selected="false">
                            <span>Groups</span>
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="caChatsTabInsideContent">
                    <div className="tab-pane fade show active" id="personal-chat" role="tabpanel" aria-labelledby="personal-chat-tab">
                        <div className="sidebar-userlist">
                            {
                                props.frndOnChat && !props.frndOnChat.last_chat && (
                                    <li onClick={()=>{clearUnreadChat(props.frndOnChat.token); selectFrnd(props.frndOnChat).then( () =>props.messageRead(props.frndOnChat.id))}}>
                                        <div className={`conversation ${props.frndOnChat.last_chat && props.frndOnChat.last_chat.sender && props.frndOnChat.last_chat.sender.id === props.user.id && ( props.frndOnChat.last_chat.read_at ?"":"unread")}  ${props.frndOnChat.frndOnChat && props.frndOnChat.id === props.frndOnChat.frndOnChat.id?'active':'false'}`}>
                                            <div className="user-avatar user-avatar-rounded online d-flex" style={{width:'3.125rem', height:'3.125rem'}}>
                                                {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                                                <h6 className='m-auto'>{props.frndOnChat.fname && props.frndOnChat.fname[0].toUpperCase()} {props.frndOnChat.lname && props.frndOnChat.lname[0].toUpperCase()}</h6>
                                            </div>
                                            <div className="conversation__details">
                                                <div className="conversation__name">
                                                    <div className="conversation__name--title">{props.frndOnChat.fname} {props.frndOnChat.lname}</div>
                                                    <div className="conversation__time">{props.frndOnChat.last_chat &&  props.frndOnChat.last_chat.created_at && (props.frndOnChat.last_chat.created_at.split(' ')[1])}</div>
                                                </div>
                                                <div className="conversation__message">
                                                    <div className="conversation__message-preview">

                                                        <span className="tick">
                                                            {/* { 
                                                                frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id && !frnd.last_chat.read_at && !frnd.last_chat.received_at && (<img src={sendTick} alt=""/>)
                                                            }
                                                            {
                                                                frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id &&frnd.last_chat.read_at && frnd.last_chat.received_at && ( <img src={readTick} alt=""/>)
                                                            }
                                                            {
                                                                frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id && !frnd.last_chat.read_at && frnd.last_chat.received_at && ( <img src={dTick} alt=""/>)

                                                            } */}
                                                        </span>
                                                        <span >
                                                            {props.frndOnChat.last_chat && props.frndOnChat.last_chat.sender && props.frndOnChat.last_chat.sender.id === props.frndOnChat.id && props.frndOnChat.last_chat.type === 'text'? (props.frndOnChat.last_chat.del_for_sender || props.frndOnChat.last_chat.del_for_all)? "message Deleted":props.frndOnChat.last_chat.body.slice(0,20)+'...' : ''}
                                                            {props.frndOnChat.last_chat && props.frndOnChat.last_chat.sender && props.frndOnChat.last_chat.sender.id !== props.frndOnChat.id && props.frndOnChat.last_chat.type === 'text'? (props.frndOnChat.last_chat.del_for_receiver || props.frndOnChat.last_chat.del_for_all)? "message Deleted":props.frndOnChat.last_chat.body.slice(0,20)+'...' : ''}

                                                            {props.frndOnChat.last_chat && props.frndOnChat.last_chat.sender && props.frndOnChat.last_chat.sender.id === props.frndOnChat.id && props.frndOnChat.last_chat.type === 'file'? (props.frndOnChat.last_chat.del_for_sender || props.frndOnChat.last_chat.del_for_all)? "message Deleted": props.frndOnChat.last_chat.body && props.frndOnChat.last_chat.body.trim().length ? props.frndOnChat.last_chat.body : props.frndOnChat.last_chat.file.name.slice(0,20)+'...' : ' ' }
                                                            {props.frndOnChat.last_chat && props.frndOnChat.last_chat.sender && props.frndOnChat.last_chat.sender.id !== props.frndOnChat.id && props.frndOnChat.last_chat.type === 'file'? (props.frndOnChat.last_chat.del_for_receiver || props.frndOnChat.last_chat.del_for_all)? "message Deleted":  props.frndOnChat.last_chat.body && props.frndOnChat.last_chat.body.trim().length ? props.frndOnChat.last_chat.body : props.frndOnChat.last_chat.file.name.slice(0,20)+'...' : ' ' }

                                                        </span>

                                                    </div>
                                                    <span className='text-success ml-2' id={'typing-'+props.frndOnChat.token}></span>
                                                    <span className="badge badge-primary badge-rounded ml-auto" id={'new-direct-message-'+ props.frndOnChat.token}></span>
                                                    
                                                    {/* <span>
                                                        <i className="mdi mdi-pin"></i>
                                                    </span> */}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                            <ul className="list-unstyled userSearchList">
                                {

                               props.friends &&( props.friends.map((frnd , index)=>(
                                    <li key={index} onClick={()=>{clearUnreadChat(frnd.token); selectFrnd(frnd).then( () =>props.messageRead(frnd.id))}}>
                                        <div className={`conversation ${frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id && ( frnd.last_chat.read_at ?"":"unread")}  ${props.frndOnChat && frnd.id === props.frndOnChat.id?'active':'false'}`}>
                                            <div className="user-avatar user-avatar-rounded online d-flex">
                                                {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                                            <h6 className='m-auto'>{frnd.fname && frnd.fname[0].toUpperCase()} {frnd.lname && frnd.lname[0].toUpperCase()}</h6>
                                            </div>
                                            <div className="conversation__details">
                                                <div className="conversation__name">
                                                    <div className="conversation__name--title">{frnd.fname} {frnd.lname}</div>
                                                    <div className="conversation__time">{frnd.last_chat &&  frnd.last_chat.created_at && (frnd.last_chat.created_at.split(' ')[1])}</div>
                                                </div>
                                                <div className="conversation__message">
                                                    <div className="conversation__message-preview">

                                                        <span className="tick">
                                                            { 
                                                                frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id && !frnd.last_chat.read_at && !frnd.last_chat.received_at && (<img src={sendTick} alt=""/>)
                                                            }
                                                            {
                                                                frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id &&frnd.last_chat.read_at && frnd.last_chat.received_at && ( <img src={readTick} alt=""/>)
                                                            }
                                                            {
                                                                frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id && !frnd.last_chat.read_at && frnd.last_chat.received_at && ( <img src={dTick} alt=""/>)

                                                            }
                                                        </span>
                                                        <span >
                                                            {/* {frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === frnd.id && frnd.last_chat.type === 'text'? (frnd.last_chat.del_for_sender || frnd.last_chat.del_for_all)? "message Deleted":frnd.last_chat.body.slice(0,20)+'...': ''} */}
                                                            {frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id  && frnd.last_chat.type === 'text'? (frnd.last_chat.del_for_sender || frnd.last_chat.del_for_all)? "message Deleted":frnd.last_chat.body.slice(0,20)+'...': ''}
                                                            {frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id !== props.user.id  && frnd.last_chat.type === 'text'? (frnd.last_chat.del_for_receiver ||  frnd.last_chat.del_for_all)? "message Deleted":frnd.last_chat.body.slice(0,20)+'...' : ''}
                                                            {/* {frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id !== frnd.id && frnd.last_chat.type === 'text'? (frnd.last_chat.del_for_receiver || frnd.last_chat.del_for_all)? "message Deleted":frnd.last_chat.body.slice(0,20)+'...' : ''} */}

                                                            {frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id === props.user.id  && frnd.last_chat.type === 'file'? (frnd.last_chat.del_for_sender || frnd.last_chat.del_for_all)? "message Deleted": (frnd.last_chat.body && frnd.last_chat.body.trim().length) ? frnd.last_chat.body.slice(0,20)+'...' : frnd.last_chat.file.name.slice(0,20)+'...': ' ' }
                                                            {frnd.last_chat && frnd.last_chat.sender && frnd.last_chat.sender.id !== props.user.id  && frnd.last_chat.type === 'file'? (frnd.last_chat.del_for_receiver || frnd.last_chat.del_for_all)? "message Deleted": ( frnd.last_chat.body && frnd.last_chat.body.trim().length )? frnd.last_chat.body.slice(0,20)+'...' : frnd.last_chat.file.name.slice(0,20)+'...': ' ' }

                                                        </span>

                                                    </div>
                                                    <span className='text-success ml-2' id={'typing-'+frnd.token}></span>
                                                    <span className="badge badge-primary badge-rounded ml-auto" id={'new-direct-message-'+ frnd.token}></span>
                                                    
                                                    {/* <span>
                                                        <i className="mdi mdi-pin"></i>
                                                    </span> */}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                               )))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="tab-pane fade position-relative" id="groups-chat" role="tabpanel" aria-labelledby="groups-chat-tab">
                        <div style={{height: '100%', width:'w-100%', display: props.groups.length?"none":'flex', alignItems:'center', justifyContent:'center'}}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div className="sidebar-userlist">
                            <ul className="list-unstyled userSearchList">
                                
                            {props.groups && props.groups.map((group, index)=>{
                             return (   <li onClick={()=>{clearUnreadChat(group.group.token); props.handleSelectFrnd(group, 0); props.messageRead(group.group.id, "for_all",group.group.id); }} key={`${group.group.token} ${index}` }>
                                    <div className={`conversation status-hidden unread ${props.frndOnChat && props.frndOnChat.group && group.group.id === props.frndOnChat.group.id?'active':'false'}`}>
                                        <div className="user-avatar user-avatar-rounded avatar-primary">
                                            <span><i className="mdi mdi-account-group"></i></span>
                                        </div>
                                        
                                        <div className="conversation__details">
                                            <div className="conversation__name">
                                                <div className="conversation__name--title">{group.group.name}</div>
                                                <div className="conversation__time">02:47 PM</div>
                                            </div>
                                            <div className="conversation__message">
                                                <div className="conversation__message-preview">
                                                    <span className="tick">
                                                        <a href="javascript:;">{group.last_chat && group.last_chat.sender && group.last_chat.sender.id === props.user.id ? 'You': group.last_chat && group.last_chat.sender ? `${group.last_chat.sender.fname}:`: '' } </a>
                                                         <span >
                                                            {group.last_chat && group.last_chat.type === 'text'? group.last_chat.body : ''}
                                                            {group.last_chat && group.last_chat.type === 'file'? (group.last_chat.body && group.last_chat.body.length) ? group.last_chat.body : group.last_chat.file.real_name: ' '}
                                                        </span>
                                                    </span>
                                                    <span className="tick">
                                                    <img src="./assets/images/tick/tick-delivered.svg" alt=""/>
                                                    </span>
                                                </div>
                                                
                                                <span><i className="mdi mdi-pin"></i></span>
                                                <span className="badge badge-primary badge-rounded" id={'new-direct-message-'+ group.group.token}></span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </li>)
                            })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    // console.log("state.friends.friends", state.friends.friends);
    return {
        groups: state.groups.groups?[...state.groups.groups]: [],
        friends: state.friends.friends?[...state.friends.friends]: [],
        user: state.user.user
    }
}

export default ChatList;