import {
  concatSeries
} from 'async';
import axios from 'axios';
import {
  SET_USER,
  SET_FRNDS,
  SET_GROUP,
  SET_ACTIVE_FRND,
  SET_ACTIVE_CHAT,
  MESSAGE_SENT,
  GROUP_MESSAGE_RECEIVED,
  UPDATE_CHAT,
  MESSAGE_SENT_GROUP,
  SET_ACTIVE_GROUP_CHAT,
  GROUP_CREATED,
  UNREAD_MESSAGE,
  MESSAGE_READ,
  MESSAGE_DELEIVERED,
  SET_ACTIVE_CHAT_NULL,
  GROUP_LAST_CHAT,
  LOAD_GROUP_MEMBERS,
  SET_LOAD_GROUP_MEMBERS,
  REMOVE_USERS_FROM_ACTIVE_GROUP,
  REMOVE_USERS,
  GROUP_MESSAGE_STATUS,
  RESET_GROUP_MESSAGE_STATUS
} from "./../types.js";


let socket_url = "https://io.icubicle.net/socket";
// let socket_url = "http://localhost:8000/socket"

const clearLocalStorage = ()=>{
  localStorage.clear();
  return window.location.reload();
}

export const loadGroupChatStatus = (data) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      dispatch({
        type: GROUP_MESSAGE_STATUS,
        payload: {
          read: [],
          delivered:[]
        },
      });
      return   axios({
        method: "post",
        headers,
        url: `/api/chat/load_chat_status`,
        data,
      }).then((response) => {
        if(response.data.success === "true"){
          return dispatch({
            type: GROUP_MESSAGE_STATUS,
            payload: response.data.message,
          });
        }
      });
    }
  }
}

export const sortFrnd = (arr) => {
  if (!arr || !arr.length) return arr;
  var currentFrnd;
  for (var i = 1; i < arr.length; i++) {
    currentFrnd = arr[i];
    for (var j = i - 1; j >= 0; j--) {
      var currentFrnddate = new Date(currentFrnd.last_chat && currentFrnd.last_chat.chat_time ? currentFrnd.last_chat.chat_time : "1999-10-01T00:00:00.000Z");
      var arrFrnddate = new Date(arr[j].last_chat && arr[j].last_chat.chat_time ? arr[j].last_chat.chat_time : "1999-10-01T00:00:00.000Z");
      if (parseInt(arrFrnddate.getTime()) > parseInt(currentFrnddate.getTime())) {
        break;
      }
      arr[j + 1] = arr[j]
    }
    arr[j + 1] = currentFrnd;
  }
  return arr;
}

export const setActiveChatNull = () => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_ACTIVE_CHAT_NULL',
      payload:''
    })
  }
}

export const Logout = () => {
  return () => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "post",
        headers,
        url: `/api/chat/logout`,
      }).then((response) => {
        if (response.data.type === "success") {
          localStorage.clear();
          return window.location.reload();
        }
      }).catch(()=>{
        clearLocalStorage();
      });
    }
  };
};

export const LoadFrnds = () => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      }
      axios({
        method: "post",
        url: "/api/chat/load_friend",
        headers,
      }).then((res) => {
        if (res.data.success === "true") {
          let data = res.data.message;
          dispatch({
            type: SET_USER,
            payload: data.user,
          });
          dispatch({
            type: SET_FRNDS,
            payload: data.friends,
          });
          axios({
            method: "post",
            url: '/api/chat/load_group',
            headers
          }).then((res) => {
            if (res.data.success === "true") {
              dispatch({
                type: SET_GROUP,
                payload: res.data.message.groups,
                // payload: [],
              });
              setTimeout(()=>{
                axios({
                  method: "post",
                  url: "/api/chat/load_group_last_chat",
                  headers
                }).then((response)=>{
                  if(response.data.success === 'true'){
  
                  } 
                })

              }, 2000)
            }
          })
          return;
        } else {}
      }).catch((err) => {
        // alert("System error")
        clearLocalStorage();
      })

    }
  }
}

export const ArrangeChat = (dispatch, data, type, frnd_id, has_next_page) => {
  if (!data.length) {
    if (!type) {
      dispatch({
        type: SET_ACTIVE_GROUP_CHAT,
        payload: {
          chats:[{ default: true }],
          group_id: frnd_id,
        },
      });
    }
    return dispatch({
      type: SET_ACTIVE_CHAT,
      payload: [{ default: true }],
      id: frnd_id
    });
  }
  let chats = [];
  let unique = {};
  for (let i = data.length - 1; i >= 0; i--) {
    let chat = data[i];
    if (i === (data.length - 1)) {
      unique.date = chat.created_at.split(' ')[0];
      unique.chat = [chat];
    } else {
      if (unique.date === chat.created_at.split(' ')[0]) {
        unique.chat.push(chat)
      } else {
        if (unique.date) {
          chats.push(unique)
        }
        unique = {}
        unique.date = chat.created_at.split(' ')[0];
        unique.chat = [chat];
      }
    }
  }
  if (unique.date) {
    chats.push(unique);
  }
  if (!type) {
    dispatch({
      type: SET_ACTIVE_GROUP_CHAT,
      payload: {
        chats,
        group_id: frnd_id,
        has_next_page
      }
    })
  }

  return dispatch({
    type: SET_ACTIVE_CHAT,
    payload: chats,
    has_next_page,
    id: frnd_id
  });

}

export const ArrangeLoadChat = (dispatch, data, type, frnd_id, has_next_page, prevChat = []) => {
  let chats = [...prevChat];
  let unique = prevChat.length ? chats.shift() : {};
  for (let i = 0; i < data.length ; i++) {
    let chat = data[i];
    if (unique.date === chat.created_at.split(' ')[0]) {
      unique.chat.push(chat)
    } else {
      if(unique.date){
        chats.unshift(unique);
      }
      unique = {}
      unique.date = chat.created_at.split(' ')[0];
      unique.chat = [chat];
    }
  }
  if (unique.date) {
    chats.unshift(unique);
  }
  if (!type) {
    dispatch({
      type: SET_ACTIVE_GROUP_CHAT,
      payload: {
        chats,
        group_id: frnd_id,
        has_next_page
      }
    })
  }
  return dispatch({
    type: SET_ACTIVE_CHAT,
    payload: chats,
    has_next_page,
    id: frnd_id
  });

}

export const SetActiveChat = (frnd, type) => {
  return (dispatch) => {
    frnd.page = frnd.page ? frnd.page : 1;
    dispatch({
      type: SET_ACTIVE_FRND,
      payload: frnd,
    });
    if (frnd.page > 1) {
      return
    }
    if (frnd.page && frnd.page === 0) {
      return
    }
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "post",
        headers,
        url: `/api/chat/load_friend_chat`,
        data: {
          frnd_id: frnd.group ? frnd.group.id : frnd.id,
          type,
          page: frnd.page
        },
      }).then((response) => {
        if (response.data.success === "true") {
          ArrangeChat(dispatch, response.data.message, frnd.group ? 0 : 1, frnd.group ? frnd.group.id : frnd.id, response.data.has_next);
          // dispatch("arrangeChat", response.data.message);
          return;
        };
        // return Logout();
      }).catch((err) => {
        if (err) {
          clearLocalStorage();
        } 
      });
    } else {
      clearLocalStorage()    }
  }
}



export const loadActiveChat = (frnd, type) => {
  return (dispatch) => {
    if (!frnd.page){
      return
    }
    frnd.page = frnd.page ? frnd.page : 1;
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "post",
        headers,
        url: `/api/chat/load_friend_chat`,
        data: {
          frnd_id: frnd.group ? frnd.group.id : frnd.id,
          type,
          page: frnd.page
        },
      }).then((response) => {
        if (response.data.success === "true") {
          ArrangeLoadChat(dispatch, response.data.message, frnd.group ? 0 : 1, frnd.group ? frnd.group.id : frnd.id, response.data.has_next, frnd.chats);
          return;
        };
        // return Logout();
      }).catch((err) => {
        if (err) {
          clearLocalStorage();
        } 
      });
    } else {
      clearLocalStorage();
      // return Logout();
    }
  }
}


export const SendMessageToFrnd = (credencials) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "post",
        headers,
        url: `/api/chat/send_message`,
        data: credencials,
      }).then((response) => {
        if (response.data.success === "true") {
          if(credencials.type){
            return dispatch({
              type: MESSAGE_SENT,
              payload: {
                message: response.data.message,
                id: response.data.user_id,
              }
            })
          }
          // if (!credencials.type) {
          //   dispatch({
          //     type: MESSAGE_SENT_GROUP,
          //     payload: {
          //       message: response.data.message,
          //       id: response.data.user_id
          //     }
          //   })
          // }
          // return dispatch({
          //   type: MESSAGE_SENT,
          //   payload: {
          //     message: response.data.message,
          //     id: response.data.user_id,
          //   }
          // })
          // ArrangeChat(dispatch, response.data.message);
          // dispatch("arrangeChat", response.data.message);
          return;
        }
        // return Logout();
      }).catch((err) => {
        // clearLocalStorage();
        if (err) {
          console.log("ooooo", err);
          // clearLocalStorage();
        } 
        // console.log("SendMessageToFrnd", eer);
      });
    } else {
      // return Logout()
      clearLocalStorage();
    }
  }
}


export const SendFilesTofrnd = (credencials) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      for (let file of credencials.attachment) {
        const data = new FormData();
        data.append("file", file);
        data.append("filename", file.name);
        data.append("receiver", credencials.receiver);
        data.append("type", credencials.type);
        data.append("message", credencials.message)
        axios({
          headers,
          method: "post",
          url: "/api/chat/send_files",
          data,
        }).then((response) => {
          if (response.data.success === "true") {
            if (!credencials.type) {
              dispatch({
                type: MESSAGE_SENT_GROUP,
                payload: {
                  message: response.data.message.chat,
                  id: response.data.user_id,
                },
              });
            }
            dispatch({
              type: MESSAGE_SENT,
              payload: {
                message: response.data.message.chat,
                id: response.data.user_id,
              },
            });
          }
        }).catch((err) => {
          // console.log("SendFilesTofrnd");
          if(err){
            clearLocalStorage();
          } 
        });
      }
    }else{
      clearLocalStorage();
    }
  }
}

export const forwardedMessageResponse = (credencials, type = 1) => {
  return (dispatch) => {
    if (!type) {
      dispatch({
        type: MESSAGE_SENT_GROUP,
        payload: {
          message: credencials,
          id: credencials.group.id,
        },
      });
    }
    return dispatch({
      type: MESSAGE_SENT,
      payload: {
        message: credencials,
        id: credencials.receiver.id,
      },
    });
  };
}

// SET USER SENT MESSAGE 
export const SocketMessageToFrnd = (credencials, type = 1) => {
  return (dispatch) => {
    if (!type) {
       dispatch({
        type: MESSAGE_SENT_GROUP,
        payload: {
          message: credencials,
          id: credencials.group.id,
        },
      });
      return dispatch({
          type: MESSAGE_SENT,
          payload: {
            message: credencials,
            id: credencials.group.id,
          },
        });
    }else {
      return dispatch({
        type: MESSAGE_SENT,
        payload: {
          message: credencials,
          id: credencials.sender.id,
        },
      });
    }
  }
}

// SET GROUP LAST CHAT TO GROUP
export const groupLastChat = (credencials) => {
  return (dispatch) => {
    dispatch({
      type: GROUP_LAST_CHAT,
      payload: {
        message: credencials,
        id: credencials.group.id,
      },
    });
  };
};

export const DeleteMessageForMe = (obj) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: "/api/chat/delete_message_for_me",
      data: {
        chats: obj
      }
    }).then((response) => {
      if (response.data.success === 'true') {

      }
    }).catch((err) => {
      if (err) {
        clearLocalStorage();
      } 
      // console.log("DeleteMessageForMe");
    })
  }
}

export const DeleteBulkMessageforMe = (obj, del_type) => {
  return (dispatch) => {

    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      let chat_ids = Object.keys(obj)

      axios({
          method: "post",
          headers,
          url: "/api/chat/delete_message_for_me",
          data: {
            del_type,
            message: chat_ids,
          },
        })
        .then((response) => {})
        .catch((err) => {
          if (err) {
            clearLocalStorage();
          } 
        });
    }else{
      clearLocalStorage();
    }
  }
}

export const SendReplyMessageToFrnd = (credential) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
          headers,
          method: "post",
          url: "/api/chat/reply_message",
          data: credential,
        })
        .then((response) => {
          if (response.data.success === "true") {
            // if (credential.type === 0) {
            //   // dispatch({
            //   //   type: GROUP_MESSAGE_RECEIVED,
            //   //   payload: response.data.message,
            //   //   id: response.data.user_id,
            //   // });
            //   dispatch({
            //     type: MESSAGE_SENT_GROUP,
            //     payload: {
            //       message: response.data.message,
            //       id: response.data.user_id,
            //     },
            //   });
            // } 
            // return dispatch({
            //   type: MESSAGE_SENT,
            //   payload: {
            //     message: response.data.message,
            //     id: response.data.user_id,
            //   },
            // });
            if(credential.type){
              return dispatch({
                type: MESSAGE_SENT,
                payload: {
                  message: response.data.message,
                  id: response.data.user_id,
                },
              });
            }
            return;
          } else {

          }
        })
        .catch((err) => {
          if (err) {
            clearLocalStorage();
          } 
        });
    }else{
      clearLocalStorage();
    }
  }
}



export const TypingAction = (token, bool, type) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token) {
      axios({
        method: "post",
        url: socket_url,
        // url: "http://localhost:4000/socket",
        data: {
          message: {
            typing: bool,
            frnd_token: localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: "",
            type
          },
          channel: "typing-message-" + token,
        },
      })
        .then((response) => {})
        .catch((err) => {
          if (err) {
            clearLocalStorage();
          } 
        });
    }else{
      clearLocalStorage();
    }
  }
}

export const OnlineAction = () => {
  return (dispatch) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      axios({
        method: "post",
        url: socket_url,
        // url: "http://localhost:8000/socket",
        data: {
          message: "message",
          channel: "am-online",
        },
      })
        .then((response) => {})
        .catch((err) => {
          if (err) {
            clearLocalStorage();
          } 
        });
    }else{
      clearLocalStorage(); 
    }
  };
};


export const OfflineAction = () => {
  return (dispatch) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      axios({
        method: "post",
        url: socket_url,
        // url: "http://localhost:8000/socket",

        data: {
          message: "message",
          channel: "am-offline",
        },
      })
        .then((response) => {})
        .catch((err) => {
          if (err) {
            clearLocalStorage();
          } 
          // console.log("OfflineAction");
        });
    }else{
      // if (err) {
      clearLocalStorage();
      // } 
    }
  };
};

export const updateChatContent = (chat) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT,
      payload: chat
    })
  }
}

export const SendMyOnlineStatus = (token, bool) => {
  return (dispatch) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      axios({
        method: "post",
        url: socket_url,
        // url: "http://localhost:8000/socket",

        data: {
          message: {
            online: bool,
            frnd_token: localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user")).sk_token
              : "",
          },
          channel: "am-online-" + token,
        },
      })
        .then((response) => {})
        .catch((err) => {
          if (err) {
            clearLocalStorage();
          } 
          // console.log("Online statuse");
        });
    }else{
      clearLocalStorage();
    }
  };
};



export const createGroup = (name, groupMembers) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
          method: "post",
          url: "/api/chat/create_chat_group",
          data: {
            group_name: name,
            group_members: groupMembers
          },
          headers,
        }).then((response) => {
          if (response.data.success === 'true') {
            if (document.getElementById("close-my-create-new-group")) {
              document.getElementById("close-my-create-new-group").click();
            }
            //  dispatch ({
            //   type: GROUP_CREATED,
            //   payload: response.data.message
            // })
            return alert("Group Created");
          }
        
        })
        .catch((err) => {
          // console.log('group creation');
          if (err) {
            clearLocalStorage();
          } 
        });
    }else{
      clearLocalStorage();
    }
  };
}
export const loadGroupMembers = (group_id) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        headers,
        url: "/api/chat/load_group_members",
        data:{
          group_id
        },
        method: "post",
      }).then((res)=>{
        if (res.data.success === 'true') {
          let obj = {

          }
          for (let i = 0; i < res.data.message.length; i++) {
            let id = res.data.message[i];
            obj[id] = true
          }
          dispatch({
            payload: {
              message: obj,
              id: group_id,
            },
            type: SET_LOAD_GROUP_MEMBERS,
          });
          // return dispatch({
          //   payload: {
          //     message: obj,
          //     id: group_id
          //   },
          //   type: LOAD_GROUP_MEMBERS
          // })
        }
      }).catch((err)=>{
        if (err) {
          clearLocalStorage();
        } 
        // console.log(err);
      })
    }else{
      clearLocalStorage();
    }
  }
}


export const addNewGroupMember = (data, members_ids) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token) {
    let token = JSON.parse(localStorage.getItem("user")).token;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    axios({
      method: "post",
      url: "/api/chat/add_group_members",
      data,
      headers
    }).then((res)=>{
      if(res.data.success === 'true'){
        let obj = {};
        for (let i = 0; i < members_ids.length; i++) {
          let id = members_ids[i];
          obj[id] = true;
        }
        dispatch({
          payload: {
            message: obj,
            id: data.group_id,
          },
          type: SET_LOAD_GROUP_MEMBERS,
        });
        dispatch({
          payload: {
            message: obj,
            id: data.group_id,
          },
          type: LOAD_GROUP_MEMBERS,
        });
        if(window.document.getElementById("add_group_members_modal_close")){
          window.document.getElementById("add_group_members_modal_close").click();
        }
        return alert("User/s Added to Group")
      }else{
        return alert("User/s Added to Group failed");
      }
    })
  }
  }
  ;
}

export const removeGroupMember = (data, members_ids) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "post",
        url: "/api/chat/remove_group_members",
        data,
        headers,
      }).then((res) => {
        if(res.data.success === 'true') {
          let obj = {};
          for (let i = 0; i < members_ids.length; i++) {
            let id = members_ids[i];
            obj[id] = true;
          }
          dispatch({
            payload: {
              message: obj,
              id: data.group_id,
            },
            type: REMOVE_USERS_FROM_ACTIVE_GROUP,
          });
          return dispatch({
            payload: {
              message: obj,
              id: data.group_id,
            },
            type: REMOVE_USERS,
          });
        }
      })
    }
  }
    ;
}

export const addedMeToGroup = (group) => {
  return (dispatch) => {
    return dispatch({
      type: GROUP_CREATED,
      payload: group
    });
  }
}

export const forwardMessage = (frnds_id, chats_id, message_type) => {
  return (dispatch) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "post",
        headers,
        url: `/api/chat/forward_message`,
        data: {
          frnds_id,
          chats_id,
          message_type,
        },
      }).then((response) => {
       
        if (response.data.success === "true") {
          // if (!credencials.type) {
          //   dispatch({
          //     type: MESSAGE_SENT_GROUP,
          //     payload: {
          //       message: response.data.message,
          //       id: response.data.user_id
          //     }
          //   });
          // }
          // return dispatch ({
          //     type: MESSAGE_SENT,
          //     payload:{
          //         message: response.data.message,
          //         id: response.data.user_id,
          //     }
          // })
          return;
        }
        // return Logout();
      }).catch(() => {
        console.log('yes');

      })
    } else {
      // return Logout();

    }
  };
};


export const loadUnreadMessage = (type=1) => {
  return (dispatch) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        headers,
        url: "/api/chat/unread_message",
        data: {message: type},
        method: "post",
      }).then((response) => {
        if (response.data.success === "true") {
         
        }
      });
    }
  }
}
export const receivedMessage = (data) => {
  return (dispatch) => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token
    ) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      return axios({
        headers,
        data,
        method: "post",
        url: "/api/chat/received_message",
      }).then((response) => {
        if (response.data.success === "true") {

        }
      })

    }
  }
}

export const messageDelievered = (data) => {
  return (dispatch) => {
    dispatch({
      payload: data,
      type: MESSAGE_DELEIVERED,
    });
  }
}
export const unreadMessage = (message) => {
  return (dispatch) => {
    return dispatch({
      payload: {
        message,
        id: message.sender.id,
      },
      type: UNREAD_MESSAGE,
    });
  }
}

export const messageRead = (frnd_id,chat_id=false, group_id=false) => {
  return (dispatch) => {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token) {
      let token = JSON.parse(localStorage.getItem("user")).token;
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      return axios({
        method: "post",
        url: "/api/chat/read_message",
        headers,
        data:(chat_id && group_id) ? {
          frnd_id,
          chat_id,
          group_id,
        }: {frnd_id},
      }).then((response) => {
        if (response.data.success === "true")
          return dispatch({
            type: MESSAGE_READ,
            payload: frnd_id,
          });
      });
    }
  }
}


// export const SendReplyMessagefile  = ( ) =>{
//   return (dispatch)=>{
//     if (localStorage.getItem("user") && JSON.parse(localStorage.getItem('user')).token ) {
//       let token = JSON.parse(localStorage.getItem("user")).token;
//       let headers = {
//         Authorization: `Bearer ${token}`,
//       };
//       for (let file of credencials.attachment) {
//         const data = new FormData();
//         data.append("file", file);
//         data.append("filename", file.name);
//         data.append("receiver", credencials.receiver);
//         data.append("type", credencials.type);
//         axios({
//           headers,
//           method: "post",
//           url: "/api/chat/reply_message",
//           data,
//         }).then((response) => {
//           if (response.data.success === "true") {
//             dispatch({
//               type: MESSAGE_SENT,
//               payload: {
//                 message: response.data.message.chat,
//                 id: response.data.user_id,
//               },
//             });
//           }
//           console.log(response.data.message);
//         });
//       }
//     }
//   }
// }