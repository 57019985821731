import React from 'react'

export default function CallList() {
    return (
        <div className="tab-pane fade" id="caCalls" role="tabpanel" aria-labelledby="caCallsTab">
            <div className="nav-style-2">
                <ul className="nav nav-pills my-3" id="caCallsTabInside" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="all-calls-tab" data-toggle="pill" href="#all-calls" role="tab" aria-controls="all-calls" aria-selected="true">All</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="missed-calls-tab" data-toggle="pill" href="#missed-calls" role="tab" aria-controls="missed-calls" aria-selected="false">Missed</a>
                    </li>
                </ul>
                <div className="tab-content" id="caCallsTabInsideContent">
                    <div className="tab-pane fade show active" id="all-calls" role="tabpanel" aria-labelledby="all-calls-tab">
                        <div className="sidebar-userlist">
                            <ul className="list-unstyled userSearchList">
                                <li>
                                    <div className="calllist">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/01.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Jack P. Angulo</div>
                                            <div className="calllist__details--info">
                                                <span><i className="mdi mdi-call-made"></i></span>
                                                <span>Today at 10:02AM</span>
                                            </div>
                                        </div>

                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-phone"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="calllist active">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/02.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Earnest Clements</div>
                                            <div className="calllist__details--info">
                                                <span><i className="mdi mdi-call-received"></i></span>
                                                <span>Today at 07:25AM</span>
                                            </div>
                                        </div>
                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-video-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="calllist">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/03.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Willie McLaughlin</div>
                                            <div className="calllist__details--info text-danger">
                                                <span><i className="mdi mdi-call-missed"></i></span>
                                                <span>Today at 07:25AM</span>
                                            </div>
                                        </div>
                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-video-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="calllist">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Andrew Showalter</div>
                                            <div className="calllist__details--info text-danger">
                                                <span><i className="mdi mdi-call-missed"></i></span>
                                                <span>Today at 07:25AM</span>
                                            </div>
                                        </div>
                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-phone"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="calllist">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/05.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Jack P. Angulo</div>
                                            <div className="calllist__details--info">
                                                <span><i className="mdi mdi-call-made"></i></span>
                                                <span>Today at 10:02AM</span>
                                            </div>
                                        </div>

                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-phone"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="calllist">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/500/09.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Earnest Clements</div>
                                            <div className="calllist__details--info">
                                                <span><i className="mdi mdi-call-received"></i></span>
                                                <span>Today at 07:25AM</span>
                                            </div>
                                        </div>
                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-video-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="missed-calls" role="tabpanel" aria-labelledby="missed-calls-tab">
                        <div className="sidebar-userlist">
                            <ul className="list-unstyled userSearchList">
                                <li>
                                    <div className="calllist active">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/03.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Willie McLaughlin</div>
                                            <div className="calllist__details--info text-danger">
                                                <span><i className="mdi mdi-call-missed"></i></span>
                                                <span>Today at 07:25AM</span>
                                            </div>
                                        </div>
                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-phone"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="calllist">
                                        <div className="user-avatar user-avatar-rounded online">
                                            <img src="./assets/images/user/250/04.jpg" alt=""/>
                                        </div>
                                        <div className="calllist__details">
                                            <div className="calllist__details--name">Andrew Showalter</div>
                                            <div className="calllist__details--info text-danger">
                                                <span><i className="mdi mdi-call-missed"></i></span>
                                                <span>Today at 07:25AM</span>
                                            </div>
                                        </div>
                                        <div className="calllist__actions">
                                            <div className="iconbox btn-hovered-light">
                                                <i className="iconbox__icon mdi mdi-video-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
