export const USER_LOGIN = "USER_LOGIN";
export const SET_USER = "SET_USER";
export const SET_FRNDS = "SET_FRNDS";
export const SET_GROUP = "SET_GROUP";
export const SET_ACTIVE_FRND = "SET_ACTIVE_FRND";
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED"; 
export const UPDATE_CHAT = "UPDATE_CHAT";
export const MESSAGE_SENT_GROUP = "MESSAGE_SENT_GROUP";
export const GROUP_MESSAGE_RECEIVED = "GROUP_MESSAGE_RECEIVED";
export const SET_ACTIVE_GROUP_CHAT = "SET_ACTIVE_GROUP_CHAT";
export const GROUP_CREATED = "GROUP_CREATED";
export const UNREAD_MESSAGE = "UNREAD_MESSAGE";
export const MESSAGE_READ = "MESSAGE_READ";
export const MESSAGE_DELEIVERED = "MESSAGE_DELEIVERED";
export const SET_ACTIVE_CHAT_NULL = "SET_ACTIVE_CHAT_NULL";
export const GROUP_LAST_CHAT = "GROUP_LAST_CHAT";
export const LOAD_GROUP_MEMBERS = "LOAD_GROUP_MEMBERS";
export const SET_LOAD_GROUP_MEMBERS = "SET_LOAD_GROUP_MEMBERS";
export const REMOVE_USERS = "REMOVE_USERS";
export const REMOVE_USERS_FROM_ACTIVE_GROUP = "REMOVE_USERS_FROM_ACTIVE_GROUP";
export const GROUP_MESSAGE_STATUS = "GROUP_MESSAGE_STATUS";
export const RESET_GROUP_MESSAGE_STATUS = "RESET_GROUP_MESSAGE_STATUS";