import React from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './pages/Login';
import {Route , Switch} from 'react-router-dom';
import Chat from './pages/Chat';


function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/chat" component={Chat} exact />
      </Switch>
    </div>
  );
}

export default App;
