import React from 'react'

export default function CallTap() {
    return (
      <div className="ca-content__callstab">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="ca-call-details-card">
                    <div className="conversation-panel__back-button ">
                      <i className="mdi mdi-arrow-left"></i>
                    </div>
                    <div className="ca-call-details__left">
                      <div className="user-avatar  user-avatar-xl user-avatar-rounded online">
                        <img src="./assets/images/user/250/02.jpg" alt="" />
                      </div>
                      <div className="ca-call-details__pname">
                        <div>Earnest Clements</div>
                        <div className="calllist__details--info">
                          <span>
                            <i className="mdi mdi-tag-outline"></i>
                          </span>
                          <span>Co-Workers</span>
                        </div>
                      </div>
                    </div>
                    <div className="ca-call-details__right">
                      <div className="iconbox btn-solid-primary">
                        <i className="iconbox__icon mdi mdi-message-text-outline"></i>
                      </div>
                      <div className="iconbox btn-solid-success">
                        <i className="iconbox__icon mdi mdi-phone"></i>
                      </div>
                      <div className="iconbox btn-solid-info">
                        <i className="iconbox__icon mdi mdi-video-outline"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="ca-call-details-history">
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-incoming-icon">
                          <i className="mdi mdi-phone-incoming"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Incoming Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-outgoing-icon">
                          <i className="mdi mdi-phone-outgoing"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Outgoing Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-success">
                          <i className="iconbox__icon mdi mdi-phone"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-missed-icon">
                          <i className="mdi mdi-phone-missed"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Missed Call</div>
                          <span>5 Minutes ago</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-incoming-icon">
                          <i className="mdi mdi-phone-incoming"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Incoming Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-incoming-icon">
                          <i className="mdi mdi-phone-incoming"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Incoming Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-incoming-icon">
                          <i className="mdi mdi-phone-incoming"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Incoming Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-outgoing-icon">
                          <i className="mdi mdi-phone-outgoing"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Outgoing Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-success">
                          <i className="iconbox__icon mdi mdi-phone"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-missed-icon">
                          <i className="mdi mdi-phone-missed"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Missed Call</div>
                          <span>5 Minutes ago</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-outgoing-icon">
                          <i className="mdi mdi-phone-outgoing"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Outgoing Call</div>
                          <span>5 Minutes ago - 12m 26s</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-success">
                          <i className="iconbox__icon mdi mdi-phone"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="ca-call-history">
                      <div className="ca-call-history__left">
                        <div className="call-outline-btn call-missed-icon">
                          <i className="mdi mdi-phone-missed"></i>
                        </div>
                        <div className="ca-call-history__left--title">
                          <div>Missed Call</div>
                          <span>5 Minutes ago</span>
                        </div>
                      </div>
                      <div className="ca-call-history__right">
                        <div className="iconbox btn-solid-info">
                          <i className="iconbox__icon mdi mdi-video-outline"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
