import React, { useState, useEffect } from "react";
import dTick from "./../../../assets/images/tick/tick-delivered.svg";
import readTick from "./../../../assets/images/tick/tick-read.svg";
import sendTick from "./../../../assets/images/tick/tick-send.svg";
import pdf from "./../../../assets/images/svg/pdf.svg";
import ForwardingModal from "./../ChatSide/ForwardingModal";
import GroupMembersModal from "./../ChatSide/GroupMembersModal";
import AddGroupMembersModal from "./../ChatSide/AddGroupMembersModal";
import './style.css'
import {
  forwardMessage,
  loadGroupMembers,
  addNewGroupMember,
  removeGroupMember,
  loadActiveChat,
  loadGroupChatStatus
} from "./../../../action/utils";
import welcomePng from "./../../../assets/images/welcome.png";
import Chat from "..";
import { connect } from "react-redux";
const $ = require("jquery");

function Message(props) {
  let [chats, setChats] = useState(props.chats);
  let [chatsToBeActedupon, setChatsToBeActedupon] = useState({});
  let [replyChat, setReplyChat] = useState(null);
  let [delType, setDelType] = useState(null);
  let [image_extention, set_image_extention] = useState(
    {
      jpg: true,
      jpeg: true,
      jpe: true,
      jif: true,
      jfif: true,
      jfi: true,
      raw: true,
      arw: true,
      cr2: true,
      nrw: true,
      k25: true,
      png: true,
      gif: true,
      webp: true,
      tiff: true,
      tif: true,
      bmp: true,
      dib: true,
      jp2: true,
      j2k: true,
      jpf: true,
      jpx: true,
      jpm: true,
      mj2: true,
      svg: true,
      svgz: true,
      ai: true,
      eps: true,
    }
  );

  var div = document.getElementById("conversation-panel__body");

  let [shouldScrollTo, setShouldScroll] = useState(0);

  let date = new Date();
  let [todaysDate, useTodaysDays] = useState(
    `${date.getFullYear()}-${date.getMonth() + 1}-${
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }`
  );

// let handleDownload = (href) => {
//   const link = document.createElement("a");
//   link.href = href;
//   link.download = true
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// }
  let handleLoadActiveChat = ()=> {
    if (div.scrollTop === 0) {
      setShouldScroll(div.scrollHeight - div.clientHeight);
      return props.loadActiveChat({...props.frndOnChat}, props.frndOnChat.group? 0: 1)
    }
  }
  useEffect(() => {
    setChats(props.chats);
    if (div && chats) {
      setTimeout(() => {
        $("#" + "conversation-panel__body").animate({scrollTop: !shouldScrollTo?div.scrollHeight - div.clientHeight:div.scrollHeight - shouldScrollTo},500)}, 500);
    }
    setReplyChat(null);
    props.handlesetReplymessage(null);
    let textarea = document.getElementById("my_message_field");
    if (textarea) {
      textarea.focus();
    }
  }, [props.chats]);

 

  if (!props.frndOnChat) {
    return (
      <div
        className="d-flex"
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        {props.user && (
          <div style={{ textAlign: "center" }}>
            <img src={welcomePng} style={{ maxWidth: "25%" }}></img>
            <h1 className="text-light h3">
              HELLO, WELCOME BACK {props.user && props.user.fname.toUpperCase()}
            </h1>
            <p className="text-secondary">Select a friend to start chatting</p>
          </div>
        )}
      </div>
    );
  }
  const handleSelectChatForMe = (chat_id, user_id) => {
    if (chatsToBeActedupon[chat_id]) {
      delete chatsToBeActedupon[chat_id];
      setChatsToBeActedupon({ ...chatsToBeActedupon });
    } else {
      setChatsToBeActedupon({ ...chatsToBeActedupon, [chat_id]: user_id });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setChatsToBeActedupon({});
  };
  const handleAddNewGroupMember = (data, members_ids) => {
    props.addNewGroupMember(data, members_ids);
  };
  const handleRemoveGroupMember = (data, members_ids) => {
    props.removeGroupMember(data, members_ids);
  };
  const handleCopy = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  const handleForward = (data) => {
    props.forwardMessage(Object.keys(data), Object.keys(chatsToBeActedupon), 1);
  };
  return (
    <div className="ca-content__chatstab">
      <div>
        <ForwardingModal
          friends={props.friends}
          handleForward={handleForward}
        />
        <GroupMembersModal
          friends={
            props.frndOnChat.group_members? [...props.friends, props.user].filter((data) => {
                  if (props.frndOnChat.group_members[data.id]) {
                    return data;
                  }
                })
              : []
          }
          frndOnChat={props.frndOnChat}
          user_id={props.user.id}
          handleRemoveGroupMember={handleRemoveGroupMember}
        />
        <AddGroupMembersModal
          friends={
            props.frndOnChat.group_members
              ? [...props.friends, props.user].filter((data) => {
                  if (!props.frndOnChat.group_members[data.id]) {
                    return data;
                  }
                })
              : []
          }
          frndOnChat={props.frndOnChat}
          user_id={props.user.id}
          handleAddNewGroupMember={handleAddNewGroupMember}
        />
        <div className="modal fade" id="webhook-modal" tabindex="-1" aria-labelledby="webhook-modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="webhook-modalLabel">Webhook</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <span style={{overflowX: "auto",width: "100%",whiteSpace:"nowrap"}}>{`https://${window.location.hostname}/api/chat/webhook/${props.frndOnChat.group? props.frndOnChat.group.token: ""}`} </span>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onclick={()=>{handleCopy(`${window.location.hostname}/Webhook/${props.frndOnChat.group? props.frndOnChat.group.token: ""}`)}}>Copy</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="view-by-modal" tabindex="-1" aria-labelledby="view-by-modal-modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="view-by-modal-modalLabel">Message Status</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <a href="#" className="list-group-item list-group-item-action bg-secondary" ariaCurrent="true">
                  <img src={readTick} alt="" /> Read
                </a>
                <div style={{height: "30vh", overflowY: "auto",color:'blue'}}>
                  <div className="list-group">
                    {
                      props.chat_status.read.map((data)=>{
                        return(
                          <a href="#" className="list-group-item list-group-item-action">{data.receiver.fname} {data.receiver.lname} <small> {data.read_at}</small> </a>
                        )
                      })
                    }
                    
                  </div>
                  
                </div>
                <a href="#" className="mt-5 list-group-item list-group-item-action bg-dark text-light" ariaCurrent="true">
                  <img src={dTick} alt="" /> Delivered
                </a>
                <div className="" style={{height: "30vh", overflowY: "auto"}}>
                  <div className="list-group">
                   {
                      props.chat_status.delivered.map((data)=>{
                        return(
                          <a href="#" className="list-group-item list-group-item-action">{data.receiver.fname} {data.receiver.lname} <small> {data.received_at}</small> </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onclick={()=>{handleCopy(`${window.location.hostname}/Webhook/${props.frndOnChat.group? props.frndOnChat.group.token: ""}`)}}>Copy</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div
        className="ca-content__chatstab--personal my-d-block  position-relative"
        id="my-personal-chat"
      >
        {!chats.length && (
          <div
            className="position-absolute d-flex"
            style={{
              height: "100vh",
              width: "100%",
              zIndex: "10",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="conversation-wrapper">
          <div className="conversation-panel">
            <div className="conversation-panel__head">
              <div
                className="conversation-panel__back-button "
                onClick={() => {
                  props.handleSetActiveChatNull();
                }}
              >
                <i className="mdi mdi-arrow-left"></i>
              </div>

              <div className="conversation-panel__avatar personalinfo-panel-opener">
                <div className="user-avatar user-avatar-rounded bg-secondary d-flex">
                  {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                  <h6 className="p-2 mx-auto">
                    <span
                      className="  mr-2 rounded-circle"
                      id={"online-indecatetor-" + props.frndOnChat.token}
                      style={{ height: "10px", width: "10px" }}
                    ></span>{" "}
                    {props.frndOnChat.group? "GRI": ` ${props.frndOnChat.fname[0].toUpperCase()} ${props.frndOnChat.lname[0].toUpperCase()}`}{" "}
                  </h6>
                </div>
                <div className="conversation__name">
                  <div className="conversation__name--title">
                    {props.frndOnChat.group
                      ? props.frndOnChat.group.name
                      : `${props.frndOnChat.fname} ${props.frndOnChat.lname}`}{" "}
                  </div>
                  <div className="conversation__time">
                    last seen{" "}
                    {props.frndOnChat.group ? "GR" : `${props.frndOnChat && props.frndOnChat.last_online? props.frndOnChat.last_online.split("T")[0]:''} ${
                          props.frndOnChat && props.frndOnChat.last_online? props.frndOnChat.last_online.split("T")[1]: ''}`}{" "}
                  </div>
                  <div
                    id={"head-typing-" + props.frndOnChat.token}
                    className="small text-success"
                  ></div>
                </div>
              </div>
              <div className="conversation__actions">
                <div
                  onClick={() => {
                    props.handleDeleteBulkMessageForMe(
                      chatsToBeActedupon,
                      delType
                    );
                    setChatsToBeActedupon({});
                  }}
                  className="action-icon"
                  data-toggle=""
                  data-target="#"
                  style={{
                    display: Object.keys(chatsToBeActedupon).length
                      ? "block"
                      : "none",
                  }}
                >
                  <i className="text-danger mdi mdi-trash-can-outline"></i>
                </div>
                <div
                  className="action-icon"
                  data-toggle="modal"
                  data-target="#outGoingCall"
                >
                  <i className="mdi mdi-phone"></i>
                </div>
                <div
                  className="action-icon"
                  data-toggle="modal"
                  data-target="#incomingVideoStart"
                >
                  <i className="mdi mdi-video-outline"></i>
                </div>

                <div className="iconbox-dropdown dropdown">
                  <div
                    className="iconbox btn-hovered-light"
                    role="button"
                    id="dropdownMenuLink2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="iconbox__icon mdi mdi-dots-vertical"></i>
                  </div>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuLink2"
                  >
                    <a
                      className="dropdown-item personalinfo-panel-opener"
                      href="n"
                    >
                      <span>
                        <i className="mdi mdi-information-outline"></i>
                      </span>
                      <span>View Contact</span>
                    </a>
                    <a className="dropdown-item" href="javascript:;">
                      <span>
                        <i className="mdi mdi-magnify"></i>
                      </span>
                      <span>Search</span>
                    </a>
                    <a className="dropdown-item" href="javascript:;">
                      <span>
                        <i className="mdi mdi-volume-mute"></i>
                      </span>
                      <span>Mute notifications</span>
                    </a>
                    <a className="dropdown-item" href="javascript:;">
                      <span>
                        <i className="mdi mdi-wallpaper"></i>
                      </span>
                      <span>Wallpaper</span>
                    </a>
                    <a className="dropdown-item" href="javascript:;">
                      <span>
                        <i className="mdi mdi-notification-clear-all"></i>
                      </span>
                      <span>Clear Chat</span>
                    </a>
                    <a className="dropdown-item" href="javascript:;">
                      <span>
                        <i className="mdi mdi-export-variant"></i>
                      </span>
                      <span>Export Chat</span>
                    </a>
                    {props.frndOnChat.group && props.frndOnChat.group.id && (
                      <a type="buttun"
                        data-toggle="modal"
                        data-target="#group-members-modal"
                        className="dropdown-item"
                        onClick={!props.frndOnChat.group_members? () => {props.loadGroupMembers(props.frndOnChat.group.id);}: () => {}}
                        href="javascript:;"
                      >
                        <span>
                          <i className="mdi mdi-eye"></i>
                        </span>
                        <span>View Group Members</span>
                      </a>
                    )}
                    {props.frndOnChat.group && props.frndOnChat.group.id && (
                      <a type="buttun"
                        data-toggle="modal"
                        data-target="#webhook-modal"
                        className="dropdown-item"
                        onClick={!props.frndOnChat.group_members? () => {props.loadGroupMembers(props.frndOnChat.group.id);}: () => {}}
                        href="javascript:;"
                      >
                        <span>
                          <i className="mdi mdi-link"></i>
                        </span>
                        <span>Webhook</span>
                      </a>
                    )}
                    {props.frndOnChat.group &&
                      parseInt(props.frndOnChat.group.author) ===
                        parseInt(props.user.id) && (
                        <a
                          type="buttun"
                          data-toggle="modal"
                          data-target="#add-group-members-modal"
                          className="dropdown-item"
                          onClick={
                            !props.frndOnChat.group_members
                              ? () => {
                                  props.loadGroupMembers(
                                    props.frndOnChat.group.id
                                  );
                                }
                              : () => {}
                          }
                          href="javascript:;"
                        >
                          <span>
                            <i className="mdi mdi-plus"></i>
                          </span>
                          <span>Add New Member</span>
                        </a>
                      )}
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="javascript:;">
                      <span>
                        <i className="mdi mdi-cancel"></i>
                      </span>
                      <span>Block</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="conversation-panel__body"
              id="conversation-panel__body"
              style={{ overflowY: "auto" }}
              onScroll={handleLoadActiveChat}
            >
              <div className="container" style={{ position: "relative"}}>
                {/* <<<<<<<<<<<<<<<<<<<<<< SHOW ATTACHED IMAGE >>>>>>>>>>>>>>>>>>>>>>>  */}
                {props.attachment && (<div className="bg-light d-flex" style={{position: 'fixed', right: '0px', left:'21.875rem',bottom:'86.8px',top:'76.6px', zIndex:10}}>
                    <div className="m-auto row">
                        <div className="image-to-display col-12 mb-5">
                            <div className="mx-auto d-flex" style={{height: '200px', width: "200px", justifyContent:'center', alignItems:'center',}} >
                              {props.attachment && props.attachment[props.active_image] && image_extention[props.attachment[props.active_image].name.split('.')[props.attachment[props.active_image].name.split('.').length-1].toLowerCase()] && (<img className="w-100 m-auto" src={URL.createObjectURL(props.attachment[props.active_image])} id="image-preview" />)}
                              {props.attachment && props.attachment[props.active_image] && !image_extention[props.attachment[props.active_image].name.split('.')[props.attachment[props.active_image].name.split('.').length-1].toLowerCase()] && (
                                <div className='text-center'>
                                  <i className="fa fa-file myFile"></i>
                                  <p className="mt-3">No preview available for <b>{props.attachment[props.active_image].name}</b></p>
                                </div>
                              )}
                            </div>
                        </div>
                        <div className="image-to-display-children mt-5 d-flex mx-auto" style={{justifyContent:'center', alignItems: 'center'}}>
                            {props.attachment && props.attachment.map((file, index)=>{
                              return (
                                <div className="px-2 d-flex image-holder" key={index} onClick={() => {props.handleActiveImage(index)}} style={{height: '100px', width: '100px', cursor: 'pointer', position:"relative", justifyContent: 'center', alignItems:'center', }}>
                                  <span className="my-remove-file" onClick={()=>{ props.removefile(index) }}><i className="fa fa-times"></i></span>
                                  {image_extention[file.name.split('.')[file.name.split('.').length - 1].toLowerCase()] ?  <img className="m-auto" src={URL.createObjectURL(file)} style={{width: '100%'}}/>: <i className="fa fa-file" style={{fontSize: '75px'}}></i>}
                                </div>
                              )
                            })}
                        </div>
                    </div>
                </div>)}
                {/* <<<<<<<<<<<<<<<<<<<<<< SHOW ATTACHED IMAGE >>>>>>>>>>>>>>>>>>>>>>>  */}

                <div className="chatstyle-01">
                  {chats.length !== 0 &&
                    !chats[0].default &&
                    chats.map((ChatDay, index) => (
                      <span key={index}>
                        {todaysDate !== ChatDay.date && (
                          <div className="chat-date">
                            <span className='text-secondary'>{ChatDay.date}</span>
                          </div>
                        )}
                        {todaysDate === ChatDay.date && (
                          <div className="chat-date">
                            <span className="badge badge-pill px-3 py-2 bg-secondary text-light">
                              Today
                            </span>
                          </div>
                        )}

                        {ChatDay.chat.map((chat, index) => (
                          <span key={index}>
                            {chat.sender.id === props.user.id &&
                              chat.type === "text" && (
                                <div className="ca-send">
                                  <div className="ca-send__msg-group">
                                    <div className="ca-send__msgwrapper">
                                      <div className="ca-msg-actions">
                                        <div className="iconbox-dropdown dropdown">
                                          {chat.del_for_sender || chat.del_for_all ? ("") : (
                                            <div
                                              className="iconbox btn-hovered-light"
                                              id="dropdownMenuButtons1"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <i className="iconbox__icon mdi mdi-dots-horizontal"></i>
                                            </div>
                                          )}
                                          {chat.del_for_sender || chat.del_for_all ? ("") : (
                                            <div
                                              className="dropdown-menu dropdown-menu-right"
                                              aria-labelledby="dropdownMenuButtons1"
                                            >
                                              {!props.frndOnChat.group && (<a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleSelectChatForMe(
                                                    chat.id,
                                                    chat.sender.id
                                                  );
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Forward</span>
                                              </a>)}
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  props.handlesetReplymessage(
                                                    chat
                                                  );
                                                }}
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Reply</span>
                                              </a>
                                              {props.frndOnChat.group && (
                                              <a className="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#view-by-modal" onClick={()=>{props.loadGroupChatStatus({chat_id:chat.id, group_id:props.frndOnChat.group.id, sender_id:chat.sender.id})}} >
                                                <span>
                                                  <i className="mdi mdi-eye"></i>
                                                </span>
                                                <span>View by</span>
                                              </a>)}

                                              <a className="dropdown-item" href="javascript:;" onClick={() => {handleCopy(chat.body);}}>
                                                <span>
                                                  <i className="mdi mdi-content-copy"></i>
                                                </span>
                                                <span>Copy</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-star-outline"></i>
                                                </span>
                                                <span>Add Star</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setDelType(props.frndOnChat.group? 2:0);
                                                  handleSelectChatForMe(
                                                    chat.id,
                                                    chat.sender.id
                                                  );
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-trash-can-outline"></i>
                                                </span>
                                                <span>Delete For Me</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setDelType(props.frndOnChat.group?3:1);
                                                  handleSelectChatForMe(
                                                    chat.id,
                                                    chat.sender.id
                                                  );
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-trash-can-outline"></i>
                                                </span>
                                                <span>Delete For Everyone</span>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div id={chat.id} className="ca-send__msg px-1" style={{maxWidth:"50vw"}}>
                                        { ( !(chat.del_for_sender || chat.del_for_all) && (chat.reply_text || chat.reply_to)) ? (
                                          <a href={`#${chat.reply_to}`} className="col-12 px-1 border-left rounded-left text-light my-bg-light d-block my-link">
                                            <h6 className="pt-1  pl-2 p">
                                              {props.user.id !== chat.sender.id ? props.frndOnChat.group ? chat.reply.sender_id ?(parseInt(chat.reply.sender_id.id) === parseInt(props.user.id)) ? "You" :`${chat.reply.sender_id.fname} ${chat.reply.sender_id.lname}`: props.frndOnChat.group.name : `${props.frndOnChat.fname} ${props.frndOnChat.lname}`: chat.reply && chat.reply.receiver_id? parseInt(chat.reply.receiver_id.id) === parseInt(props.user.id) ?  `${props.frndOnChat.fname} ${props.frndOnChat.lname}`    :  "You "  : `${chat.reply.sender_id ? chat.reply.sender_id.fname : ""} ${chat.reply.sender_id? chat.reply.sender_id.lname: ""}`}
                                            </h6>
                                            <p className="small mb-0">
                                              {chat.del_for_sender || chat.del_for_all ? "Message Deleted" : chat.reply_text ? chat.reply_text: chat.reply.body}
                                            </p>
                                          </a>
                                        ): ""}
                                        {chat.del_for_sender || chat.del_for_all ? "Message Deleted": chat.body}
                                      </div>
                                    </div>
                                    <div className="metadata">
                                      <span className="time">
                                        {chat.created_at.split(" ")[1]}
                                      </span>
                                      <span className="tick">
                                        {chat.read_at && chat.received_at && (
                                          <img src={readTick} alt="" />
                                        )}
                                        {!chat.read_at && !chat.received_at && (
                                          <img src={sendTick} alt="" />
                                        )}
                                        {!chat.read_at && chat.received_at && (
                                          <img src={dTick} alt="" />
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="user-avatar user-avatar-sm user-avatar-rounded online d-flex bg-primary">
                                    {/* <img src="./assets/images/user/250/02.jpg" alt=""/> */}
                                    <h6 className="mx-auto text-light p-2">
                                      {chat.sender.fname[0].toUpperCase()}{" "}
                                      {chat.sender.lname[0].toUpperCase()}
                                    </h6>
                                  </div>
                                  <div
                                    style={{
                                      display: Object.keys(chatsToBeActedupon)
                                        .length
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {chat.del_for_sender || chat.del_for_all ? (
                                      ""
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={chatsToBeActedupon[chat.id]}
                                        value={chat.id}
                                        onChange={(e) => {
                                          handleSelectChatForMe(
                                            chat.id,
                                            chat.sender.id
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            {parseInt(chat.sender.id) !==
                              parseInt(props.user.id) &&
                              chat.type === "text" && (
                                <div className="ca-received">
                                  <div
                                    style={{
                                      display: Object.keys(chatsToBeActedupon)
                                        .length
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {chat.del_for_receiver ||
                                    chat.del_for_all ? (
                                      ""
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={chatsToBeActedupon[chat.id]}
                                        value={chat.id}
                                        onChange={(e) => {
                                          handleSelectChatForMe(
                                            chat.id,
                                            chat.sender.id
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="user-avatar user-avatar-sm user-avatar-rounded online d-flex bg-secondary">
                                    {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                                    <h6 className="text-light p-2">
                                      {props.frndOnChat.group? props.frndOnChat.group.name[0].toUpperCase(): `${props.frndOnChat.fname[0].toUpperCase()} ${props.frndOnChat.lname[0].toUpperCase()}`}{" "}
                                    </h6>
                                  </div>
                                  <div className="ca-received__msg-group">
                                    <div className="ca-received__msgwrapper">
                                      <div id={chat.id} className="ca-send__msg px-1" style={{maxWidth:"50vw"}} >
                                        {
                                          props.frndOnChat.group? (<div > <span className='small px-2 text-light member_name'>{chat.sender.fname} { chat.sender.lname}</span></div>):''
                                        }
                                        {(!(chat.del_for_receiver || chat.del_for_all) && (chat.reply_text || chat.reply_to) )? (
                                          <a href={`#${chat.reply_to}`} className="col-12 px-1 px-1 border-left rounded-left text-light my-bg-light d-block my-link">
                                            <h6 className="pt-1 p">
                                              {props.user.id !== chat.sender.id ? props.frndOnChat.group ? chat.reply.sender_id ?(parseInt(chat.reply.sender_id.id) === parseInt(props.user.id)) ? "You" :`${chat.reply.sender_id.fname} ${chat.reply.sender_id.lname}`: props.frndOnChat.group.name : `${props.frndOnChat.fname} ${props.frndOnChat.lname}`: chat.reply && chat.reply.receiver_id? parseInt(chat.reply.receiver_id.id) === parseInt(props.user.id) ? `${props.frndOnChat.fname} ${props.frndOnChat.lname}`   :  "You " : `${chat.reply.sender_id ? chat.reply.sender_id.fname : ""} ${chat.reply.sender_id? chat.reply.sender_id.lname: ""}`}
                                            </h6>
                                            <p className="small mb-0">
                                              {chat.del_for_receiver || chat.del_for_all ? "Message Deleted" : chat.reply_text ? chat.reply_text: chat.reply.body} 
                                            </p>
                                          </a>
                                        ): ''}
                                        {(chat.del_for_receiver || chat.del_for_all)? "Message Deleted": chat.body}{" "}
                                      </div>
                                      <div className="ca-msg-actions">
                                        <div className="iconbox-dropdown dropdown">
                                          {chat.del_for_receiver ||
                                          chat.del_for_all ? (
                                            ""
                                          ) : (
                                            <div
                                              className="iconbox btn-hovered-light"
                                              id="dropdownMenuButtonsr1"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <i className="iconbox__icon mdi mdi-dots-horizontal"></i>
                                            </div>
                                          )}
                                          {chat.del_for_receiver ||
                                          chat.del_for_all ? (
                                            ""
                                          ) : (
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButtonsr1"
                                            >
                                              {!props.frndOnChat.group && (<a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleSelectChatForMe(
                                                    chat.id,
                                                    chat.sender.id
                                                  );
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Forward</span>
                                              </a>)}
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  props.handlesetReplymessage(
                                                    chat
                                                  );
                                                }}
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Reply</span>
                                              </a>
                                              {props.frndOnChat.group && (
                                              <a className="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#view-by-modal" onClick={()=>{props.loadGroupChatStatus({chat_id:chat.id, group_id:props.frndOnChat.group.id,sender_id:chat.sender.id })}} >
                                                <span>
                                                  <i className="mdi mdi-eye"></i>
                                                </span>
                                                <span>View by</span>
                                              </a>)}
                                              <a
                                                data-toggle="modal" data-target="#exampleModal"
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={() => {
                                                  handleCopy(chat.body);
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-content-copy"></i>
                                                </span>
                                                <span>Copy</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-star-outline"></i>
                                                </span>
                                                <span>Add Star</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setDelType(props.frndOnChat.group? 2:0);
                                                  handleSelectChatForMe(chat.id,chat.sender.id);
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-trash-can-outline"></i>
                                                </span>
                                                <span>Delete For Me</span>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="metadata">
                                      <span className="time">
                                        {chat.created_at.split(" ")[1]}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {chat.sender.id === props.user.id &&
                              chat.type === "file" && (
                                <div className="ca-send">
                                  <div className="ca-send__msg-group">
                                    <div className="ca-send__msgwrapper">
                                      <div className="ca-msg-actions">
                                        <div className="iconbox-dropdown dropdown">
                                          {chat.del_for_sender ||chat.del_for_all ? ("") : (
                                            <div className="iconbox btn-hovered-light" id="dropdownMenuButtons1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              <i className="iconbox__icon mdi mdi-dots-horizontal"></i>
                                            </div>
                                          )}
                                          {chat.del_for_sender || chat.del_for_all ? ("") : (
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButtons1">
                                              {!props.frndOnChat.group  && (<a className="dropdown-item" href="javascript:;" onClick={(e) => {e.preventDefault(); handleSelectChatForMe(chat.id,chat.sender.id);}}>
                                                <span><i className="mdi mdi-share-outline"></i> </span>
                                                <span>Forward</span>
                                              </a>)}
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  props.handlesetReplymessage(
                                                    chat
                                                  );
                                                }}
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Reply</span>
                                              </a>
                                              {props.frndOnChat.group && (
                                              <a className="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#view-by-modal" onClick={()=>{props.loadGroupChatStatus({chat_id:chat.id, group_id:props.frndOnChat.group.id,sender_id:chat.sender.id })}} >
                                                <span>
                                                  <i className="mdi mdi-eye"></i>
                                                </span>
                                                <span>View by</span>
                                              </a>)}

                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={() => {
                                                  handleCopy(chat.file.name);
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-content-copy"></i>
                                                </span>
                                                <span>Copy</span>
                                              </a>
                                              <a
                                                className="dropdown-item cursor" 
                                                href={`/static/files/${chat.file.location}${chat.file.real_name}`}
                                                download={true}
                                                target="_blank" rel="noopener noreferrer"
                                                >
                                                <span>
                                                  <i className="mdi mdi-content-copy"></i>
                                                </span>
                                                <span>Download</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-star-outline"></i>
                                                </span>
                                                <span>Add Star</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {e.preventDefault();setDelType(props.frndOnChat.group? 2:0);handleSelectChatForMe(chat.id,chat.sender.id);}}>
                                                <span>
                                                  <i className="mdi mdi-trash-can-outline"></i>
                                                </span>
                                                <span>Delete For Me</span>
                                              </a>
                                              <a className="dropdown-item" href="javascript:;" onClick={(e) => {e.preventDefault();setDelType(props.frndOnChat.group?3:1); handleSelectChatForMe(chat.id,chat.sender.id);}}>
                                                <span>
                                                  <i className="mdi mdi-trash-can-outline"></i>
                                                </span>
                                                <span>Delete For Everyone</span>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="ca-send__msg px-1" style={{maxWidth:"50vw"}} > 
                                        <div className="ca__file" style={{display: chat.file && chat.file.name?image_extention[chat.file.name.split('.')[1]]?'block': 'flex':'flex'}}>
                                          <div className="ca__fileicon">
                                            {
                                              chat.del_for_sender || chat.del_for_all? "": (<img style={{maxWidth: '100%'}} src={chat.file && chat.file.name? image_extention[chat.file.name.split('.')[1]]? `/static/files/${chat.file.location}${chat.file.real_name}` :pdf:pdf} alt="" />)
                                            }
                                          </div>
                                          <div id={chat.id} className="ca__filename w-100">
                                            <span className="ca__filename--title">
                                              {( !(chat.del_for_sender || chat.del_for_all ) && (chat.reply_text || chat.reply_to)) ? (
                                                <a
                                                  href={`#${chat.reply_to}`}
                                                  className="col-12px-1 border-left rounded-left text-light my-bg-light d-block my-link"
                                                >
                                                  <h6 className="pt-1  pl-2 p">
                                              {props.user.id  !== chat.sender.id ? props.frndOnChat.group ? chat.reply.sender_id ?(parseInt(chat.reply.sender_id.id) === parseInt(props.user.id)) ? "You" :`${chat.reply.sender_id.fname} ${chat.reply.sender_id.lname}`: props.frndOnChat.group.name : `${props.frndOnChat.fname} ${props.frndOnChat.lname} `: chat.reply && chat.reply.receiver_id? parseInt(chat.reply.receiver_id.id) === parseInt(props.user.id) ? `${props.frndOnChat.fname} ${props.frndOnChat.lname}`   : "You " : `UNKNOWN  USER`}

                                                  </h6>
                                                  <p className="small mb-0 ml-auto">
                                                    {chat.del_for_sender || chat.del_for_all ? "Message Deleted" : chat.reply_text ? chat.reply_text: chat.reply.body} 
                                                  </p>
                                                </a>
                                              ): ''}
                                              {chat.del_for_sender ||
                                              chat.del_for_all
                                                ? "Message Deleted"
                                                : chat.body && chat.body.trim().length ? chat.body : chat.file.name}
                                                
                                            </span>
                                            {/* <span className="ca__filename--size">21Kb</span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="metadata">
                                      <span className="time">
                                        {chat.created_at.split(" ")[1]}
                                      </span>
                                      <span className="tick">
                                        {chat.read_at && chat.received_at && (
                                          <img src={readTick} alt="" />
                                        )}
                                        {!chat.read_at && !chat.received_at && (
                                          <img src={sendTick} alt="" />
                                        )}
                                        {!chat.read_at && chat.received_at && (
                                          <img src={dTick} alt="" />
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="user-avatar user-avatar-sm user-avatar-rounded online d-flex bg-primary">
                                    {/* <img src="./assets/images/user/250/02.jpg" alt=""/> */}
                                    <h6 className="mx-auto text-light p-2">
                                      {chat.sender.fname[0].toUpperCase()}{" "}
                                      {chat.sender.lname[0].toUpperCase()}
                                    </h6>
                                  </div>
                                  <div
                                    style={{
                                      display: Object.keys(chatsToBeActedupon)
                                        .length
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {chat.del_for_sender || chat.del_for_all ? (
                                      ""
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={chatsToBeActedupon[chat.id]}
                                        value={chat.id}
                                        onChange={(e) => {
                                          handleSelectChatForMe(
                                            chat.id,
                                            chat.sender.id
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            {chat.sender.id !== props.user.id &&
                              chat.type === "file" && (
                                <div className="ca-received">
                                  <div
                                    style={{display: Object.keys(chatsToBeActedupon).length? "block" : "none"}}>
                                    {chat.del_for_receiver || chat.del_for_all ? ("") : (
                                      <input
                                        type="checkbox"
                                        checked={chatsToBeActedupon[chat.id]}
                                        value={chat.id}
                                        onChange={(e) => {
                                          handleSelectChatForMe(
                                            chat.id,
                                            chat.sender.id
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="user-avatar user-avatar-sm user-avatar-rounded online d-flex bg-secondary">
                                    {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                                    <h6 className="mx-auto text-light p-2">
                                      {props.frndOnChat.group
                                        ? props.frndOnChat.group.name[0].toUpperCase()
                                        : `${props.frndOnChat.fname[0].toUpperCase()} ${props.frndOnChat.lname[0].toUpperCase()}`}
                                    </h6>
                                  </div>
                                  <div className="ca-received__msg-group">
                                    <div className="ca-received__msgwrapper">
                                      <div className="ca-send__msg px-1" style={{maxWidth:"50vw"}} >
                                        <div className="ca__file" style={{display:chat.file && chat.file.name? image_extention[chat.file.name.split('.')[1]]?'block': 'flex':'flex'}}>
                                          <div className="ca__fileicon">
                                            {chat.del_for_receiver || chat.del_for_all ? '': (<img style={{maxWidth: '100%'}} src={chat.file && chat.file.name ? image_extention[chat.file.name.split('.')[1]]? `/static/files/${chat.file.location}${chat.file.real_name}` :pdf :pdf} alt="" />)}
                                          </div>
                                          <div
                                            id={chat.id}
                                            className="ca__filename"
                                          >
                                            <span className="ca__filename--title">
                                              {
                                                props.frndOnChat.group? (<div className='small px-2 text-light member_name'>{chat.sender.fname} { chat.sender.lname}</div>):''
                                              }
                                              {(!(chat.del_for_receiver || chat.del_for_all) && (chat.reply_text || chat.reply_to)) ? (
                                                <a
                                                  href={`#${chat.reply_to}`}
                                                  className="col-12 px-1 border-left rounded-left text-light my-bg-light d-block my-link "
                                                >
                                                  <h6 className="pt-1  pl-2 p">
                                                  {props.user.id !== chat.sender.id ? props.frndOnChat.group ? chat.reply.sender_id ?(parseInt(chat.reply.sender_id.id) === parseInt(props.user.id)) ? "You" :`${chat.reply.sender_id.fname} ${chat.reply.sender_id.lname}`: props.frndOnChat.group.name : `${props.frndOnChat.fname} ${props.frndOnChat.lname}`: chat.reply && chat.reply.receiver_id? parseInt(chat.reply.receiver_id.id) === parseInt(props.user.id) ?  `${props.frndOnChat.fname} ${props.frndOnChat.lname}`  :  "You "  :   `${chat.reply.sender_id ? chat.reply.sender_id.fname : ""} ${chat.reply.sender_id? chat.reply.sender_id.lname: ""}`}

                                                  </h6>
                                                  <p className="small mb-0">
                                                    {(chat.del_for_receiver || chat.del_for_all) ? "Message Deleted" : chat.reply_text ? chat.reply_text: chat.reply.body} 
                                                  </p>
                                                </a>
                                              ):""}
                                              {chat.del_for_receiver ||
                                              chat.del_for_all
                                                ? "Message Deleted"
                                                : chat.body && chat.body.trim().length ? chat.body : chat.file.name}
                                            </span>
                                            {/* <span className="ca__filename--size">21Kb</span> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ca-msg-actions">
                                        <div className="iconbox-dropdown dropdown">
                                          {chat.del_for_receiver ||
                                          chat.del_for_all ? (
                                            ""
                                          ) : (
                                            <div
                                              className="iconbox btn-hovered-light"
                                              id="dropdownMenuButtonsr1"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <i className="iconbox__icon mdi mdi-dots-horizontal"></i>
                                            </div>
                                          )}
                                          {chat.del_for_receiver ||
                                          chat.del_for_all ? (
                                            ""
                                          ) : (
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButtonsr1"
                                            >
                                              {!props.frndOnChat.group && (<a className="dropdown-item" href="javascript:;" onClick={(e) => {e.preventDefault();handleSelectChatForMe(chat.id,chat.sender.id);}}>
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Forward</span>
                                              </a>)}
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  props.handlesetReplymessage(
                                                    chat
                                                  );
                                                }}
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-share-outline"></i>
                                                </span>
                                                <span>Reply</span>
                                              </a>
                                              {props.frndOnChat.group && (
                                              <a className="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#view-by-modal" onClick={()=>{props.loadGroupChatStatus({chat_id:chat.id, group_id:props.frndOnChat.group.id,sender_id:chat.sender.id })}} >
                                                <span>
                                                  <i className="mdi mdi-eye"></i>
                                                </span>
                                                <span>View by</span>
                                              </a>)}
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={() => {
                                                  handleCopy(chat.body.trim().length ? chat.body : chat.file.name);
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-content-copy"></i>
                                                </span>
                                                <span>Copy</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href={`/static/files/${chat.file.location}${chat.file.real_name}`}
                                                download={true}
                                                target="_blank" rel="noopener noreferrer"
                                              >
                                                <span>
                                                  <i className="mdi mdi-content-copy"></i>
                                                </span>
                                                <span>Download</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                              >
                                                <span>
                                                  <i className="mdi mdi-star-outline"></i>
                                                </span>
                                                <span>Add Star</span>
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setDelType(props.frndOnChat.group? 2:0);
                                                  handleSelectChatForMe(
                                                    chat.id,
                                                    chat.sender.id
                                                  );
                                                }}
                                              >
                                                <span>
                                                  <i className="mdi mdi-trash-can-outline"></i>
                                                </span>
                                                <span>Delete For me</span>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="metadata">
                                      <span className="time">
                                        {chat.created_at.split(" ")[1]}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </span>
                        ))}
                      </span>
                    ))}
                  {props.frndOnChat.unreadChat &&
                    props.frndOnChat.unreadChat.length !== 0 && (
                      <div className="new-message text-primary h4 ">
                        <span className="badge badge-pill badge-secondary py-2 px-3">
                          New Message
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>

            {chats.length !== 0 && (
              <div className="conversation-panel__footer">
                {Object.keys(chatsToBeActedupon).length > 0 && (
                  <div className="row col-12 py-2">
                    <div className="col col-lg-3 small text-secondary">
                      <span
                        className="btn btn-secondary rounded-circle btn-sm"
                        id="set_chats_to_be_acted_upon"
                        onClick={() => {
                          setChatsToBeActedupon({});
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </span>{" "}
                      {Object.keys(chatsToBeActedupon).length} Selected
                    </div>
                    <div></div>
                    <div className=" ml-auto col col-lg-3">
                      <span className="btn btn-warning  rounded-circle btn-sm">
                        <i className="fas fa-star"></i>
                      </span>
                      <span
                        className="btn btn-danger rounded-circle mx-2 btn-sm"
                        onClick={() => {
                          props.handleDeleteBulkMessageForMe(
                            chatsToBeActedupon,
                            delType
                          );
                          setChatsToBeActedupon({});
                        }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </span>
                      <span
                        className="btn btn-primary rounded-circle btn-sm"
                        type="buttun"
                        data-toggle="modal"
                        data-target="#staticBackdrop"
                      >
                        <i className="fas fa-share"></i>
                      </span>
                    </div>
                  </div>
                )}

                {props.replyChat &&
                  Object.keys(chatsToBeActedupon).length === 0 && (
                    <div className="col-12 row py-2">
                      <div className="ml-auto border col-10 ">
                        <h6 className="pt-1  pl-2">
                          {props.user.id !== props.replyChat.sender.id
                            ? props.frndOnChat.group
                              ? props.frndOnChat.group.name
                              : `${props.frndOnChat.fname} ${props.frndOnChat.lname}`
                            : "You"}
                        </h6>
                        <p className="text-secondary small p-2">
                          {props.replyChat.type === "text"
                            ? props.replyChat.body
                            : props.replyChat.file.name}
                        </p>
                      </div>
                      <div className="col-1 d-flex">
                        <i
                          onClick={() => {
                            setDelType(null);
                            props.handlesetReplymessage(null);
                          }}
                          className="fa fa-times m-auto"
                        ></i>
                      </div>
                    </div>
                  )}
                {Object.keys(chatsToBeActedupon).length === 0 && (
                  <form className="composer" onSubmit={props.handleSendMessage}>
                    <div className="composer__left">
                      <div className="composer__left--sticker">
                        <i className="mdi mdi-sticker-emoji"></i>
                      </div>
                      <div className="composer__left--emoticon">
                        <i className="mdi mdi-emoticon-outline"></i>
                      </div>
                    </div>

                    <div className="composer__middle">
                      <textarea
                        value={props.message}
                        id="my_message_field"
                        onChange={props.handleSetMessage}
                        className="form-control"
                        rows={props.rows}
                        placeholder="Type a message..."
                      ></textarea>

                      <div
                        className="composer__middle--microphone"
                        style={{ zIndex: 1 }}
                      >
                        <i className="mdi mdi-microphone"></i>
                      </div>
                      <div className="composer__middle--photo" style={{ zIndex: 1 }}>
                        <input
                          type="file"
                          style={{
                            position: "absolute",
                            width: "20px",
                            opacity: "0",
                            zIndex: 0,
                            left: 'auto',
                            right: 'auto'
                          }}
                          accept=".jpg,.jpeg,.jpe,.jif,.png,.gif"
                          multiple
                          onChange={props.handleSetAttachment}
                        />
                        <i className="mdi mdi-camera"></i>
                      </div>
                      <div
                        className="composer__middle--attachment"
                        style={{ position: "absolute" }}
                      >
                        <input
                          type="file"
                          style={{
                            position: "absolute",
                            width: "20px",
                            opacity: "0",
                            zIndex: 0,
                          }}
                          multiple
                          onChange={props.handleSetAttachment}
                        />
                        <i
                          className="mdi mdi-attachment"
                          style={{ zIndex: -2 }}
                        >
                          {" "}
                          <span className="text-danger ">
                            {props.attachment ? props.attachment.length : ""}
                          </span>
                        </i>
                      </div>
                    </div>

                    <div className="composer__right" style={{ zIndex: 1 }}>
                      <div
                        className="composer__right--send"
                        onClick={props.handleSendMessage}
                      >
                        <i className="mdi mdi-send"></i>
                      </div>
                      <div className="composer__right--microphone">
                        <i className="mdi mdi-microphone"></i>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>

          <div className="information-panel personal-information-panel">
            <div className="information-panel__head">
              <h5>Contact info</h5>
              <div className="information-panel__closer">
                <i className="mdi mdi-close"></i>
              </div>
            </div>

            <div className="information-panel__body">
              <div className="userprofile-avatar">
                <img
                  className="img-fluid"
                  src="./assets/images/user/250/01.jpg"
                  alt=""
                />
              </div>

              <div className="userprofile-name">
                <h4>Jack P. Angulo</h4>
                <span>Product Manager</span>
                <div className="social-icon-box">
                  <div className="social-icon">
                    <i className="mdi mdi-facebook"></i>
                  </div>
                  <div className="social-icon">
                    <i className="mdi mdi-linkedin"></i>
                  </div>
                  <div className="social-icon">
                    <i className="mdi mdi-twitter"></i>
                  </div>
                  <div className="social-icon">
                    <i className="mdi mdi-youtube"></i>
                  </div>
                </div>
              </div>

              <hr />

              <table className="table table-sm table-borderless user-table-info">
                <tbody>
                  <tr>
                    <td>
                      <i className="mdi mdi-cellphone-android"></i>
                    </td>
                    <td>+91 99041-99041</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="mdi mdi-web"></i>
                    </td>
                    <td>www.jackangulo.com</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="mdi mdi-map-marker"></i>
                    </td>
                    <td>2519 Burnside Court, HORICON, WI, 53032</td>
                  </tr>
                </tbody>
              </table>

              <hr />

              <div
                className="accordion accordion-ungrouped mb-3"
                id="accordionExample"
              >
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <div
                      className="card-title"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="acpanel__heading">
                        <div className="acpanel__left">
                          <span>
                            <i className="mdi mdi-camera-outline"></i>
                          </span>
                          <span>Photos & Media</span>
                        </div>
                        <div className="acpanel__right">
                          <span className="badge badge-info">26</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <div className="owl-carousel">
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="./assets/images/media/01.jpg"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="./assets/images/media/02.jpg"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="./assets/images/media/03.jpg"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="./assets/images/media/04.jpg"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="./assets/images/media/05.jpg"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="./assets/images/media/06.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <div
                      className="card-title collapsed d-flex justify-content-between align-items-center"
                      role="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div className="acpanel__heading">
                        <div className="acpanel__left">
                          <span>
                            <i className="mdi mdi-file-document-outline"></i>
                          </span>
                          <span>Documents</span>
                        </div>
                        <div className="acpanel__right">
                          <span className="badge badge-info">16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <ul className="list-unstyled documentlist-wrapper">
                        <li>
                          <div className="doclist">
                            <div className="docicon">
                              <img src="./assets/images/svg/pdf.svg" alt="" />
                            </div>
                            <div className="doctitle">
                              <div className="docname">example-file.pdf</div>
                              <div className="docsize">217kb</div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="doclist">
                            <div className="docicon">
                              <img src="./assets/images/svg/word.svg" alt="" />
                            </div>
                            <div className="doctitle">
                              <div className="docname">example-file.docs</div>
                              <div className="docsize">217kb</div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="doclist">
                            <div className="docicon">
                              <img src="./assets/images/svg/excel.svg" alt="" />
                            </div>
                            <div className="doctitle">
                              <div className="docname">example-file.xlxs</div>
                              <div className="docsize">217kb</div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="doclist">
                            <div className="docicon">
                              <img
                                src="./assets/images/svg/powerpoint.svg"
                                alt=""
                              />
                            </div>
                            <div className="doctitle">
                              <div className="docname">example-file.pptx</div>
                              <div className="docsize">217kb</div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="doclistall">View All</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFour">
                    <div
                      className="card-title collapsed  d-flex justify-content-between align-items-center"
                      role="button"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div className="acpanel__heading">
                        <div className="acpanel__left">
                          <span>
                            <i className="mdi mdi-settings-outline"></i>
                          </span>
                          <span>Settings</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <ul className="list-unstyled">
                        <li>
                          <div className="setting-list">
                            <div className="setting-list-name">
                              Media Auto Download
                            </div>
                            <div className="setting-list-switch">
                              <label className="material-switch">
                                <input type="checkbox" />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="setting-list">
                            <div className="setting-list-name">
                              Mute Conversation
                            </div>
                            <div className="setting-list-switch">
                              <label className="material-switch">
                                <input type="checkbox" />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="setting-list">
                            <div className="setting-list-name">
                              Notifications
                            </div>
                            <div className="setting-list-switch">
                              <label className="material-switch">
                                <input type="checkbox" />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="setting-list">
                            <div className="setting-list-name">Block</div>
                            <div className="setting-list-switch">
                              <label className="material-switch">
                                <input type="checkbox" />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  forwardMessage,
  loadGroupMembers,
  addNewGroupMember,
  removeGroupMember,
  loadActiveChat,
  loadGroupChatStatus,
})(Message);
