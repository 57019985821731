import React, {useState, useEffect} from 'react';
import Login from './Login';
import { LoginUser, MySetSate } from "./../../action/auth.js";
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';
import { USER_LOGIN} from './../../types.js';

function LoginContainer( props ) {
    let [password, setPassword] = useState('');
    let [email, setEmail] = useState('');
    
    let handleSubmit = (e) => {
        e.preventDefault();
        document.getElementById("login-loader").style.display = "block";

        let obj = {
            email,
            password
        }
        return props.LoginUser(obj);
    }
    useEffect( (p) =>{
        // let search = window.location.search;
        // let params = new URLSearchParams(search);
        // let q = params.get("q");
        // if(q && q.trim().length){
        //     return props.LoginUser(q);
        // }else if(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).token) {

        // }else{
        //     return window.location.replace("/");
        //     // return window.location.replace("https://cor.icubicle.net");
        // }
    }, [] )
    if(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).token){
        props.MySetSate(USER_LOGIN, JSON.parse(localStorage.getItem("user")));
        return <Redirect to="/chat" />;
    }
    if (props.token) {
        return <Redirect to='/chat'/>;
    }
    return (
        <div>
            <Login password={password} setPassword={setPassword} setEmail={setEmail}  email={email} handleSubmit={handleSubmit}/>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        token: state.user.token ,
    }
}

export default connect(mapStateToProps, { LoginUser, MySetSate })(LoginContainer);
