import React, { Component } from 'react'
import  ChatContainer from '../components/Chat'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./../assets/fonts/MaterialDesign/css/materialdesignicons.min.css";
import {connect} from 'react-redux'
import { Redirect } from "react-router-dom";
import {SocketContext} from "./socket"

import {
  LoadFrnds,
  SocketMessageToFrnd,
  forwardedMessageResponse,
  OfflineAction,
  OnlineAction,
  TypingAction,
  updateChatContent,
  SendMyOnlineStatus,
  addedMeToGroup,
  receivedMessage,
  loadUnreadMessage,
  unreadMessage,
  sortFrnd,
  messageDelievered,
  messageRead,
  groupLastChat,
} from "./../action/utils.js";
import "./../css/theme/default.css";
import io  from 'socket.io-client';
const Push = require("push.js");

let sk_token = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token:""

class Chat extends Component {
  static contextType = SocketContext;
  constructor(props) {
    super(props);
    this.state = {
      isGroup: false,
      tokenSet: false,
      // Socket: io(this.props.socket_url, { transports: ['websocket'] }),
      Socket: this.context,
      token: "",
      channel: "private-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      typingChannel: "typing-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      onlineChannel: "am-online-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      offlineChannel: "am-offline",
      messageDeletedForMe: "private-delete-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      groupmessageChannel: "group-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      addedToGroupChannel: "added-to-group-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      unreadSpanId: "new-direct-message-",
      yourForwardedMessage: "your-forwarded-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      messageDelieved: "friend-received-my-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      unReadMessage: "unread-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      groupLastChat: "group-last-message-" + (localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).sk_token: ""),
      mySocket: null,
    };
    this.handleSendTyping = this.handleSendTyping.bind(this);
  }
  componentWillMount(props) {
    if (!this.props.token) return <Redirect to="/" />;
    let Socket = this.context;
    this.setState({ Socket });
    
  }
  componentDidMount() {
    
    this.connectSocket();
    if (!Push.Permission.has()) {
      Push.Permission.request(
        (onGranted) => {
          Push.create("Hello notification allowed successfully", {
            body: "And we're back :).",
            icon: "/icon.png",
            timeout: 10000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        },
        (onDenied) => {
          // Push.Permission.get();
          // Push.create("allow notification ");
          alert("You need to allow notification on this page");
        }
      );
    }
    let r = this;
    async function loadAllFrnds() {
      r.props.LoadFrnds();
    }
    loadAllFrnds().then(() => {
      r.props.loadUnreadMessage();
    });

    // console.log(Push.Permission.has());
  }
  componentDidUpdate() {
    if (this.props.frndOnChat && this.props.frndOnChat.token) {
      // <<<<<<<<<< MUST BE UNCOMMITED >>>>>>>>>>>>>>>>>>
      // this.props.SendMyOnlineStatus(this.props.frndOnChat.token, true);
    }
  }

  connectSocket = () => {
    // let Socket = this.context
    if(!this.state.Socket) return
    this.state.Socket.on("connect", (s) => {
      console.log("connected");
      let r = this;
      // this.props.OnlineAction();
      // let online = setInterval(() => {
      //   this.props.OnlineAction();
      // }, 15000);
      // Socket.emit("user_is_typing", {
      //   message: "typing",
      //   username: "user",
      // });

      // Socket.on("typing-message-b541b0ccc9422452", (d) => {
      // });
      window.addEventListener(
        "online",
        function (e) {
          Push.create(
            `Hello ${r.props.user && r.props.user.fname} ${
              r.props.user && r.props.user.lname
            }`,
            {
              body: "And we're back :).",
              icon: "/icon.png",
              timeout: 10000,
              onClick: function () {
                window.focus();
                this.close();
              },
            }
          );
          r.props.loadUnreadMessage(0);
        },
        false
      );
      window.addEventListener(
        "offline",
        function (e) {
          // clearInterval(online);
          Push.create(
            `Hello ${r.props.user && r.props.user.fname} ${
              r.props.user && r.props.user.lname
            }`,
            {
              body: "Connection is down.",
              icon: "/icon.png",
              timeout: 10000,
              onClick: function () {
                window.focus();
                this.close();
              },
            }
          );
        },
        false
      );
      // <<<<<<<<<<<<<<< NOTIFICATION  DONE>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.channel, (data) => {
        let spanElem = document.getElementById(
          this.state.unreadSpanId + data.sender.token
        );
        if (spanElem) {
          if (
            data.sender &&
            this.props.frndOnChat &&
            data.sender.id === this.props.frndOnChat.id
          ) {
            spanElem.innerHTML = "";
          } else {
            spanElem.innerHTML = spanElem.innerHTML
              ? parseInt(spanElem.innerHTML) + 1
              : 1;
          }
        }
        Push.create(`${data.sender.fname} ${data.sender.lname}`, {
          body: data.type === "text" ? data.body : data.file.name,
          icon: "/icon.png",
          timeout: 10000,
          onClick: function () {
            window.focus();
            this.close();
          },
        });
        this.props.SocketMessageToFrnd(data);
        if (
          data.sender &&
          this.props.frndOnChat &&
          data.sender.id === this.props.frndOnChat.id
        ) {
          this.props.receivedMessage({ chat_id: data.id });
          this.props.messageRead(data.sender.id);
        } else {
          this.props.receivedMessage({ chat_id: data.id });
        }
      });
      // <<<<<<<<<<<<<<< USER ADDED TO A GROUP >>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.addedToGroupChannel, (data) => {
        Push.create(`${data.group.name} `, {
          body: `Hello . You can now chat with us`,
          icon: "/icon.png",
          timeout: 10000,
          onClick: function () {
            window.focus();
            this.close();
          },
        });
        this.props.addedMeToGroup(data);
      });

      // <<<<<<<<<<<<<<< GROUP NOTIFICATION >>>>>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.groupmessageChannel, (data) => {
        if (data.sender.token !== this.props.sk_token) {
          if(this.props.frndOnChat && this.props.frndOnChat.group && data.group.token === this.props.frndOnChat.group.token) {
            this.props.receivedMessage({ chat_id: data.id, group_id:data.group.id });
            console.log(data.sender.id, data.id, data.group.id );
            this.props.messageRead(data.sender.id, data.id, data.group.id);
          } else {
            let spanElem = document.getElementById(this.state.unreadSpanId + data.group.token);
            if (spanElem) {
              spanElem.innerHTML = spanElem.innerHTML? parseInt(spanElem.innerHTML) + 1: 1;
            }
          }
          Push.create(`${data.group.name}`, {
            body: data.type === "text" ? data.body : data.file.name,
            icon: "/icon.png",
            timeout: 10000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
          this.props.SocketMessageToFrnd(data, 0);
          this.props.receivedMessage({ chat_id: data.id, group_id:data.group.id });

        }else if(data.sender.token === this.props.sk_token ){
          if (this.props.frndOnChat && this.props.frndOnChat.group && data.group.token === this.props.frndOnChat.group.token) {
            return this.props.SocketMessageToFrnd(data, 0);
          }
        }
      });

      // <<<<<<<<<<<<<<< GROUP LAST CHAT >>>>>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.groupLastChat, (data) => {
        // this.props.SocketMessageToFrnd(data, 0);
        if (data.body) {
          this.props.groupLastChat(data);
        }
      });
      //  <<<<<<<<<<<<<<< FORWARDED MESSAGE RESSPONSE DONE >>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.yourForwardedMessage, (data) => {
        this.props.forwardedMessageResponse(data);
      });
      // <<<<<<<<<<<<<<<< FRIEND TYPING DONE >>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.typingChannel, (msg) => {
        let typingDiv = document.getElementById("typing-" + msg.frnd_token);
        let typingDivHearder = document.getElementById(
          "head-typing-" + msg.frnd_token
        );
        if (typingDivHearder && msg.typing) {
          typingDivHearder.innerHTML = "typing...";
        } else if (typingDivHearder && !msg.typing) {
          typingDivHearder.innerHTML = "";
        }
        if (typingDiv && msg.typing) {
          typingDiv.innerHTML = "typing...";
        } else if (typingDiv && !msg.typing) {
          typingDiv.innerHTML = "";
        }
      });
      // <<<<<<<<<<<<<<<< GROUP TYPING DONE >>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.typingChannel, (msg) => {
        let typingDiv = document.getElementById("typing-" + msg.frnd_token);
        let typingDivHearder = document.getElementById(
          "head-typing-" + msg.frnd_token
        );
        if (typingDivHearder && msg.typing) {
          typingDivHearder.innerHTML = "typing...";
        } else if (typingDivHearder && !msg.typing) {
          typingDivHearder.innerHTML = "";
        }
        if (typingDiv && msg.typing) {
          typingDiv.innerHTML = "typing...";
        } else if (typingDiv && !msg.typing) {
          typingDiv.innerHTML = "";
        }
      });

      // <<<<<<<<<<<<<<<<<< FRIEND ONLINE >>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.onlineChannel, (msg) => {
        let onlineDiv = document.getElementById(
          "online-indecatetor-" + msg.token
        );
        if (onlineDiv && msg.online) {
          onlineDiv.style.backgroundColor = "green";
        } else if (onlineDiv && !msg.online) {
          onlineDiv.style.backgroundColor = "white";
        }
        // TO BE ON COMMITED
        // this.props.SendMyOnlineStatus(msg.frnd_token, true);
      });

      // <<<<<<<<<<<<<<<<<< FRIEND OFFLINE >>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.offlineChannel, (token) => {});

      // <<<<<<<<<<<<<<<<<<< Message Delievered >>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.messageDelieved, (data) => {
        this.props.messageDelievered(data);
      });

      //<<<<<<<<<<<<<<<<<< Unread Message  DONE>>>>>>>>>>>>>>>>>>>
      this.state.Socket.on(this.state.unReadMessage, (data) => {
        let spanElem = document.getElementById(
          this.state.unreadSpanId + data.sender.token
        );
        if (spanElem) {
          spanElem.innerHTML = spanElem.innerHTML
            ? parseInt(spanElem.innerHTML) + 1
            : 1;
        }
        this.props.unreadMessage(data);
        // this.props.receivedMessage({ chat_id: data.id });
      });
    });
    //<<<<<<<<<<<<< CHAT DELETE  DONE>>>>>>>>>>>>>>>>>>>>
    this.state.Socket.on(this.state.messageDeletedForMe, (chat) => {
      this.props.updateChatContent(chat);
    });
    this.state.Socket.on("disconnect", (s) => {
      console.log("socket is disconnected");
    });
  };
  handleSendTyping(token, type, typing) {
    // let Socket = this.context
    if (this.state.Socket.connected) {
      this.state.Socket.emit("user_is_typing", {
        message: "typing",
        token,
        type,
        frnd_token: sk_token,
        typing,
      });
    }
  }
  render() {
    if (!this.props.token) return <Redirect to="/" />;
    return (
      <div>
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
            display: this.props.friends.length ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
            display:
              this.props.frndOnChat &&
              this.props.frndOnChat.id &&
              !this.props.frndOnChat.chats
                ? "flex"
                : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <ChatContainer
          friends={this.props.friends}
          handleSendTyping={this.handleSendTyping}
          frndOnChat={
            this.props.frndOnChat ? { ...this.props.frndOnChat } : null
          }
        />
      </div>
    );
  }
}

const mapStateToProps =  (state) => {
  let sortedFriends = [];
  if (state.friends.friends){
    sortedFriends = sortFrnd(Object.values(state.friends.friends));
  }
  return {
    token: state.user.token,
    socket_url: state.user.socket_url,
    sk_token: state.user.sk_token,
    user: state.user.user ? { ...state.user.user } : null,
    frndOnChat: state.friends.frndOnChat
      ? { ...state.friends.frndOnChat }
      : null,
    groups: state.groups.groups ? [...state.groups.groups] : [],
    friends: sortedFriends,
  };
}

export default connect(mapStateToProps, {
  LoadFrnds,
  SocketMessageToFrnd,
  OfflineAction,
  OnlineAction,
  TypingAction,
  updateChatContent,
  SendMyOnlineStatus,
  forwardedMessageResponse,
  addedMeToGroup,
  loadUnreadMessage,
  receivedMessage,
  unreadMessage,
  messageDelievered,
  messageRead,
  groupLastChat,
})(Chat);