import React, { useEffect } from "react";
import './style.css'

export default function Login(props) {
    let {email , setEmail, password, setPassword, handleSubmit} = props
    useEffect(()=>{
        document.getElementById("login-loader").style.display = 'none';
        
    }, [])
    return (
        <div className="container" id="app">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">
                            <h5 className="card-title text-center">iCubicle</h5>
                            <hr className="my-4"/>
                            <div className="text-center " id="login-loader">
                                <img src="https://files.icubicle.net/assets/img/loader.gif" />
                            </div>
                            <p className="text-center">Sign In</p>
                            <form className="form-signin" action="#"  onSubmit={handleSubmit}>
                                <div className="form-label-group form-group">
                                    <input type="email" id="inputEmail" className="form-control" name="email"
                                        placeholder="Email address" value={email} onChange={ (e)=>{e.preventDefault(); setEmail(e.target.value)}} required autoFocus />
                                    <label htmlFor="inputEmail">Email address</label>
                                </div>

                                <div className="form-label-group">
                                    <input type="password" id="inputPassword" className="form-control" name="password"
                                        value={password} onChange={ (e)=>{e.preventDefault(); setPassword(e.target.value)}}  placeholder="Password" required />
                                    <label htmlFor="inputPassword">Password</label>
                                </div>

                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" /> 
                                    <label className="custom-control-label" htmlFor="customCheck1">Remember password</label>
                                </div>
                                <button onClick={handleSubmit} style={{backgroundColor: 'rgb(47, 64, 80)'}}
                                    className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
