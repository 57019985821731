import { auto } from 'async';
import React, {useState, useEffect} from 'react'

export default function AddGroupMembersModal(props) {
    let [newMembers, setNewMembers] = useState({});
    let [myFriend, SetMyFriend] = useState([]);
    let [search, setSearch] = useState('')
    let handleForwardingMembers = (frnd) => {
        if (!newMembers[frnd.id]) {
            newMembers[frnd.id] = {
                token: frnd.token,
                fname: frnd.fname,
                lname: frnd.lname,
                id: frnd.id
            }
        }else {
            delete newMembers[frnd.id];
        }
        setNewMembers({...newMembers})
    }
    let handlefilterSearch = (data) => {
        let myFriend = [...props.friends].filter((frnd)=>{
            if ((frnd.lname + '  ' + frnd.fname).toLowerCase().indexOf(data) > -1 ) {
                return frnd
            }   
        })
        if (!data.length) {
          SetMyFriend(props.friends);
        } else {
          SetMyFriend(myFriend);
        }
    }

    let handleAddNewMember = (data) => {
        let obj = {
          group_id: props.frndOnChat.group && props.frndOnChat.group.id,
          members: Object.values(data),
        };
        props.handleAddNewGroupMember(obj, Object.keys(data));
        
    };
    useEffect(()=>{
        SetMyFriend(props.friends)
    },[props.friends])
    return (
        <div className="modal fade " id="add-group-members-modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="newGroupModalLabel">Non Group Members of <span className='text-success'>{ props.frndOnChat.group && props.frndOnChat.group.name} </span></h6>
                                <button type="button" className="close" id="add_group_members_modal_close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="group-members">
                                    <div className="group-members__title">
                                        <h6>Selected Users</h6>
                                        <span>20/100</span>
                                    </div>
                                    <div className="group-members__avatar" >
                                        {
                                            Object.values(newMembers).map(( member, index )=>{
                                            if (index < 8) {
                                                return (
                                                    <div className="user-avatar user-avatar-rounded border">
                                                        {/* <img src="./assets/images/user/250/01.jpg" alt=""/> */}
                                                <span className=''>{`${member.fname[0].toUpperCase()} ${member.lname[0].toUpperCase()}`}</span>
                                                        <div className="user-avatar-closer" onClick={()=>{
                                                            handleForwardingMembers(member);
                                                        }}><i className="mdi mdi-close"></i></div>
                                                    </div>
                                                )

                                            }
                                        })
                                    }
                                        {/* <div className="user-avatar user-avatar-rounded">
                                            <img src="./assets/images/user/250/02.jpg" alt=""/>
                                            <div className="user-avatar-closer"><i className="mdi mdi-close"></i></div>
                                        </div> */}
                                    </div>
                                </div>
                                <hr/>
                                <div className="searchbar">
                                    <div className="input-group">
                                        <input type="text" className="form-control" value={search} onChange={(e)=>{
                                            e.preventDefault();
                                            setSearch(e.target.value);
                                            handlefilterSearch(e.target.value);
                                        }}  placeholder="Search" aria-label="Search"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text dialpad-opener"><i className="mdi mdi-dialpad"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-contact-list" style={{overflowY: 'auto'}}>
                                    <ul className="list-unstyled">
                                        {
                                            myFriend.map((frnd, index)=>{
                                                return(   <li key={index + frnd.id}>
                                                    <div className="contactlist">
                                                        <div className="user-avatar user-avatar-rounded" style={{display: 'flex', justifyContent: 'center',alignItems:'center', height:'50px', width:'50px'}}>
                                                            {/* <img src="./assets/images/user/500/06.jpg" alt=""/> */}
                                                            <h2 className='h6'>{frnd.fname && frnd.fname[0].toUpperCase()} { frnd.lname && frnd.lname[0].toUpperCase()}</h2>
                                                        </div>
                                                        <div className="contactlist__details">
                                                        <div className="contactlist__details--name">{frnd.fname} {frnd.lname}</div>
                                                            <div className="calllist__details--info">
                                                                <span><i className="mdi mdi-tag-outline"></i></span>
                                                              {props.frndOnChat.group && props.frndOnChat.group.author && parseInt(props.frndOnChat.group.author) === parseInt(frnd.id) && <span className='text-success'>Creater/Admin {parseInt(frnd.id)=== parseInt(props.user_id) && <span className='text-danger bold ml-3'>You</span>} </span>}
                                                              {props.frndOnChat.group && props.frndOnChat.group.author && parseInt(props.frndOnChat.group.author) !== parseInt(frnd.id) && <span className='text-secondary'>Member {parseInt(frnd.id)=== parseInt(props.user_id) && <span className='text-danger bold ml-3'>You</span>}</span>}

                                                            </div>
                                                        </div>

                                                        <div className="contactlist__actions">
                                                             <div className={newMembers[frnd.id]? 'iconbox btn-solid-success':'iconbox btn-solid-info'} onClick={(e) => {
                                                                handleForwardingMembers(frnd);
                                                            }}>
                                                                <i className={newMembers[frnd.id]? "iconbox__icon mdi mdi-check": "iconbox__icon mdi mdi-plus"}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>)

                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="modal-dialpad">
                                    <div className="dial-pad-wrap">
                                        <div className="dial-pad">
                                            <div className="dial-screen">+01-(363)-2612</div>
                                            <div className="dial-table">
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="1">
                                                        <div className="dial-key">1</div>
                                                        <div className="dial-sub-key">&nbsp;</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="2">
                                                        <div className="dial-key">2</div>
                                                        <div className="dial-sub-key">abc</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="3">
                                                        <div className="dial-key">3</div>
                                                        <div className="dial-sub-key">def</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="4">
                                                        <div className="dial-key">4</div>
                                                        <div className="dial-sub-key">ghi</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="5">
                                                        <div className="dial-key">5</div>
                                                        <div className="dial-sub-key">jkl</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="6">
                                                        <div className="dial-key">6</div>
                                                        <div className="dial-sub-key">mno</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="7">
                                                        <div className="dial-key">7</div>
                                                        <div className="dial-sub-key">pqrs</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="8">
                                                        <div className="dial-key">8</div>
                                                        <div className="dial-sub-key">tuv</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="9">
                                                        <div className="dial-key">9</div>
                                                        <div className="dial-sub-key">wxyz</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row">
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="*">
                                                        <div className="dial-key">*</div>
                                                        <div className="dial-sub-key">&nbsp;</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="0">
                                                        <div className="dial-key">0</div>
                                                        <div className="dial-sub-key">+</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="#">
                                                        <div className="dial-key">#</div>
                                                        <div className="dial-sub-key">&nbsp;</div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="dial-table-row no-sub-key">
                                                    <div className="dial-table-col">
                                                            
                                                        <div className="dial-key-wrap" data-key="save-contact">
                                                            <div className="dial-key"><i className="mdi mdi-account-plus-outline"></i></div>
                                                            <div className="dial-sub-key">Call</div>
                                                        </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="call">
                                                        <div className="dial-key"><i className="mdi mdi-plus-circle-outline"></i></div>
                                                        <div className="dial-sub-key">Call</div>
                                                    </div>
                                                    </div>
                                                    <div className="dial-table-col">
                                                    <div className="dial-key-wrap" data-key="back">
                                                        <div className="dial-key"><i className="mdi mdi-arrow-left"></i></div>
                                                        <div className="dial-sub-key">Back</div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <button className='btn btn-danger mt-3 ml-auto' onClick={(e)=>{e.preventDefault();  handleAddNewMember(newMembers);}}>Add</button>
                            
                            </div>
                        </div>
                    </div>
        </div>
    )
}
