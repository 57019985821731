import React, { Component } from 'react'
import LoginContainer from '../components/Login'

export default class Login extends Component {
    render() {
        return (
            <div>
                <LoginContainer/>
            </div>
        )
    }
}
