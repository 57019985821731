import {
  SET_FRNDS,
  SET_ACTIVE_CHAT,
  SET_ACTIVE_FRND,
  MESSAGE_RECEIVED,
  UNREAD_MESSAGE,
  MESSAGE_SENT,
  UPDATE_CHAT,
  GROUP_MESSAGE_RECEIVED,
  MESSAGE_READ,
  MESSAGE_DELEIVERED,
  SET_ACTIVE_CHAT_NULL,
  SET_LOAD_GROUP_MEMBERS,
  REMOVE_USERS_FROM_ACTIVE_GROUP,
  GROUP_MESSAGE_STATUS,
  RESET_GROUP_MESSAGE_STATUS,
} from "../types.js";


let initialState = {
  friends: null,
  frndOnChat: null,
  chat: [],
  chat_status: {
    read: [],
    delivered:[]
  }
}

function SortFrnd(arr) {
  if (!arr || !arr.length) return arr;
  var currentFrnd;
  for (var i = 1; i < arr.length; i++) {
    currentFrnd = arr[i];
    for (var j = i - 1; j >= 0; j--) {
      var currentFrnddate = new Date(currentFrnd.last_chat.chat_time ? currentFrnd.last_chat.chat_time : "1999-10-01T00:00:00.000Z");
      var arrFrnddate = new Date(arr[j].last_chat.chat_time ? arr[j].last_chat.chat_time : "1999-10-01T00:00:00.000Z");
      if (parseInt(arrFrnddate.getTime()) > parseInt(currentFrnddate.getTime())) {
        break;
      }
      arr[j + 1] = arr[j]
    }
    arr[j + 1] = currentFrnd;
  }
  return arr;
}

function SortChatDays(arr) {
  if (!arr || !arr.length) return arr;
  var currentChatDay;
  for (var i = 1; i < arr.length; i++) {
    currentChatDay = arr[i];
    for (var j = i - 1; j >= 0; j--) {
      var currentFrnddate = new Date(currentChatDay.date ? currentChatDay.date : "1999-10-01T00:00:00.000Z");
      var arrFrnddate = new Date(arr[j].date ? arr[j].date : "1999-10-01T00:00:00.000Z");
      if (parseInt(arrFrnddate.getTime()) === parseInt(currentFrnddate.getTime())) {
        currentChatDay.chat = [...currentChatDay.chat, ...arr[j].chat]
        arr[j].chat = []
        arr[j].date = ''
      }
      if (parseInt(arrFrnddate.getTime()) > parseInt(currentFrnddate.getTime())) {
        break;
      }
      arr[j + 1] = arr[j]
    }
    arr[j + 1] = currentChatDay;
  }
  return arr;
}

function convertFriendsArrIntoObject(arr) {
  let obj = {}
  for (let i = 0; i < arr.length; i++) {
    let frnd = arr[i];
    obj[frnd.id] = frnd
  }
  return obj
}

const frndsReducer = (state = initialState,{ type, payload, has_next_page, id}) => {
  switch (type) {
    case SET_FRNDS:
      // COMMITTED TO BE REMOVED LATER
      // return { ...state, friends: SortFrnd(payload) };
      let obj =  {
        ...state,
        friends: convertFriendsArrIntoObject(payload),
      };
      return obj
    case SET_ACTIVE_FRND:
      return {...state,frndOnChat: payload, chat: payload.chats && payload.chats.length ? payload.chats : []};
    case SET_LOAD_GROUP_MEMBERS:
      if (state.frndOnChat.group && state.frndOnChat.group.id && state.frndOnChat.group.id === payload.id) {
        if (state.frndOnChat.group_members) {
          state.frndOnChat.group_members = {
            ...state.frndOnChat.group_members,
            ...payload.message,
          };
        } else {
          state.frndOnChat.group_members = payload.message;
        }
      }
      return { ...state };
    case REMOVE_USERS_FROM_ACTIVE_GROUP:
      if (state.frndOnChat.group && state.frndOnChat.group.id && state.frndOnChat.group.id === payload.id) {
        if (state.frndOnChat.group_members) {
          let m = {
            ...state.frndOnChat.group_members,
          };
          let o = Object.keys(m).map((data)=>{
            if(payload.message[data]){
              delete m[data];
            }
          });
          state.frndOnChat.group_members = m
        } 
      }
      return {...state};
    case SET_ACTIVE_CHAT:
      if (!state.frndOnChat.group && state.friends[id]) {
        state.friends[id].chats = payload;
        if (has_next_page === "true") {
          state.friends[id].page = state.friends[id].page? state.friends[id].page + 1: 2;
        } else {
          state.friends[id].page = 0;
        }
        if (parseInt(state.frndOnChat.id) === parseInt(id)) {
          state.frndOnChat.page = state.friends[id].page;
          state.chat = state.friends[id].chats;
        }
        return { ...state};
      } else if (state.frndOnChat.group && parseInt(state.frndOnChat.group.id) === parseInt(id)) {
        return { ...state, chat: payload };
      }
    
    case UPDATE_CHAT:
      let chat = payload;
      let date = chat.created_at.split(" ")[0];
      if (state.chat.length) {
        let chats;
        let index;
        for (let i = 0; i < state.chat.length; i++) {
          if (state.chat[i].date === date) {
            chats = state.chat[i].chat;
            index = i;
            break;
          }
        }
        chats = chats ? chats.map((c) => {
              if (c.id === chat.id) return chat;
              return c;
            })
          : null;
        state.chat[index].chat = chats;
        if (state.frndOnChat.last_chat.id === chat.id) {
          state.frndOnChat.last_chat = chat;
        }
      }
      let frndArray = Object.values(state.friends);

      for (let i = 0; i < frndArray.length; i++) {
        let frnd = frndArray[i];
        if (frnd.last_chat.id === chat.id) {
          state.friends[frnd.id].last_chat = chat;
          state.friends[frnd.id].chats = state.chat;
          break;
        }
      }
      return {
        ...state,
      };

    case MESSAGE_SENT:
      if (!state.friends) return { ...state };
      if (
        state.frndOnChat &&
        state.frndOnChat.group &&
        state.frndOnChat.group.id === payload.id
      ) {
        
        return {
          ...state,
        };
      }

      if (state.frndOnChat && state.frndOnChat.id === payload.id) {
        // chat = state.chat.push(payload)
        if (state.friends[payload.id]) {
          state.friends[payload.id].last_chat = payload.message;
          // frnd.last_chat = payload.message;
          if (state.chat && state.chat[0] && state.chat[0].default) {
            state.chat = [];
            state.friends[payload.id].chats = [];
          }
          state.friends[payload.id].chats = [...state.chat]
          let lastDay = state.friends[payload.id].chats? state.friends[payload.id].chats[state.friends[payload.id].chats.length - 1]: null;
        
          if (lastDay && lastDay.date === payload.message.created_at.split(" ")[0]) {
            state.friends[payload.id].chats[state.friends[payload.id].chats.length - 1].chat.push(payload.message);
          } else {
            let newChatDay = {
              date: payload.message.created_at.split(" ")[0],
              chat: [payload.message],
            };
            state.friends[payload.id].chats = state.friends[payload.id].chats && state.friends[payload.id].chats.length? state.friends[payload.id].chats.push(newChatDay): [newChatDay];
            // state.friends[payload.id].chats = state.friends[payload.id].chats ? state.friends[payload.id].chats.push(newChatDay): [newChatDay];
            //  state.friends[payload.id].chats.push(newChatDay)
            // frnd.chats.push(newChatDay);
            state.chat = state.friends[payload.id].chats? state.friends[payload.id].chats : [];
          }
        }
        state.frndOnChat.last_chat = payload.message;
      } else if (state.friends) {
        if (state.friends[payload.id]) {
          // frnd.last_chat = payload.message;
          state.friends[payload.id].last_chat = payload.message;
          let lastDay = state.friends[payload.id].chats
            ? state.friends[payload.id].chats[
                state.friends[payload.id].chats.length - 1
              ]
            : null;
          if (
            lastDay &&
            lastDay.date === payload.message.created_at.split(" ")[0]
          ) {
            // frnd.chats[frnd.chats.length - 1].chat.push(payload.message);
            state.friends[payload.id].chats[
              state.friends[payload.id].chats.length - 1
            ].chat.push(payload.message);
          } else {
            let newChatDay = {
              date: payload.message.created_at.split(" ")[0],
              chat: [payload.message],
            };
            state.friends[payload.id].chats = state.friends[payload.id].chats && state.friends[payload.id].chats.length? state.friends[payload.id].chats.push(newChatDay): [newChatDay];
            // frnd.chats = frnd.chats && frnd.chats.length ? frnd.chats.push(newChatDay) : [newChatDay];
          }
        }
      }
      // state.friends = SortFrnd(state.friends);
      return { ...state };

    case UNREAD_MESSAGE:
      if (
        state.frndOnChat &&
        state.frndOnChat.group &&
        state.frndOnChat.group.id === payload.id
      ) {
        let lastDay = state.chats ? state.chats[state.chats.length - 1] : null;
        if (
          lastDay &&
          lastDay.date === payload.message.created_at.split(" ")[0]
        ) {
          state.chats[state.chats.length - 1].chat.push(payload.message);
        } else {
          let newChatDay = {
            date: payload.message.created_at.split(" ")[0],
            chat: [payload.message],
          };
          state.chats.push(newChatDay);
        }
        return {
          ...state,
        };
      }
      if (state.friends && state.friends[payload.id]) {
        state.friends[payload.id].last_chat = payload.message;
        let set = false;
        state.friends[payload.id].chats = state.friends[payload.id].chats
          ? state.friends[payload.id].chats
          : [];
        for (let i = 0; i < state.friends[payload.id].chats.length; i++) {
          let chat = state.friends[payload.id].chats[i];
          if (chat.date === payload.message.created_at.split(" ")[0]) {
            chat.chat.push(payload.message);
            set = true;
            break;
          }
        }
        if (!set) {
          let newChatDay = {
            date: payload.message.created_at.split(" ")[0],
            chat: [payload.message],
          };
          state.friends[payload.id].chats.push(newChatDay);
        }
      }
      //  else {
      //   let newChatDay = {
      //     date: payload.message.created_at.split(" ")[0],
      //     chat: [payload.message],
      //   };
      //   state.friends[payload.id].chats = [newChatDay];
      // }

      // state.friends = SortFrnd(state.friends);

      return {
        ...state,
      };

    case MESSAGE_READ:
      let frnd_id = payload;
      if (state.friends[frnd_id]) {
        let hasChats = false;
        if (state.friends[frnd_id].chats && state.friends[frnd_id].unreadChat) {
          state.friends[frnd_id].chats = [
            ...state.friends[frnd_id].chats,
            ...state.friends[frnd_id].unreadChat,
          ];
          hasChats = true;
        } else {
          state.friends[frnd_id].chats = state.friends[frnd_id].unreadChat;
        }
        state.friends[frnd_id].unreadChat = [];
        if (hasChats) {
          // sort the chat
          // state.friends[frnd_id].chats= SortChatDays(state.friends[frnd_id].chats)
        }
      }
      return { ...state };

    case MESSAGE_DELEIVERED:
      if (state.friends[payload.receiver_id]) {
        if (!state.friends[payload.receiver_id].chats) return { ...state };
        for (
          let i = state.friends[payload.receiver_id].chats.length - 1;
          i >= 0;
          i--
        ) {
          let chat = state.friends[payload.receiver_id].chats[i];
          if (chat.date === payload.chat.created_at.split(" ")[0]) {
            for (let g = chat.chat.length - 1; g >= 0; g--) {
              let mainChat = chat.chat[g];
              if (mainChat.id === payload.chat.id) {
                mainChat.received_at = payload.chat.received_at;
                mainChat.seen = payload.chat.seen;
                mainChat.read_at = payload.chat.read_at;
                break;
              }
            }
            //  chat.chat = chat.chat.map((c)=>{
            //     if (c.id === payload.chat.id){
            //       let new_c = {...c, ...payload.chat}
            //       return new_c
            //     }else{
            //       return c
            //     }
            //   })
            if (state.frndOnChat.id === payload.receiver_id) {
              state.chat[i] = { ...chat };
            }
            break;
          }
        }
      }
      return { ...state };
    case GROUP_MESSAGE_RECEIVED:
      if (
        state.frndOnChat &&
        state.frndOnChat.group &&
        state.frndOnChat.group.id === payload.id
      ) {
      }
      return;
    case GROUP_MESSAGE_STATUS: 
      let chat_status = {...payload};
      return { ...state, chat_status: chat_status };
    case RESET_GROUP_MESSAGE_STATUS:
      return {...state, chat_status: []}
    default:
      return state;
  }
};

export default frndsReducer;