import React, { Component } from 'react';
import CallTap from './CallTap';
import Message from "./Message";
import ContactTab from "./ContactTab";
import {
  SendMessageToFrnd,
  SendFilesTofrnd,
  SendReplyMessageToFrnd,
  TypingAction,
  DeleteBulkMessageforMe,
  setActiveChatNull,
} from "./../../../action/utils.js";
import {connect} from 'react-redux';



class ChatSideContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      attachment: null,
      replyChat: null,
      forwardChat: null,
      rows: 1,
      13: false,
      16: false,
      active_image: 0,
    };
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleSetAttachment = this.handleSetAttachment.bind(this);
    this.handleSetMessage = this.handleSetMessage.bind(this);
    this.handleReplyMessage = this.handleReplyMessage.bind(this);
    this.handlesetReplymessage = this.handlesetReplymessage.bind(this);
    this.handleSetActiveChatNull = this.handleSetActiveChatNull.bind(this);
    this.handleDeleteBulkMessageForMe = this.handleDeleteBulkMessageForMe.bind(this);
    this.handleActiveImage = this.handleActiveImage.bind(this);
    this.removefile= this.removefile.bind(this);
  }

  componentDidMount(props) {
    window.addEventListener("keyup", () => {
      if (this.props.frndOnChat && this.state.message.length) {
        this.props.handleSendTyping(this.props.frndOnChat.group ? this.props.frndOnChat.group.token: this.props.frndOnChat.token, this.props.frndOnChat.group ? 0 : 1, true );

          // this.props.TypingAction(this.props.frndOnChat.group? this.props.frndOnChat.group.token
          //     : this.props.frndOnChat.token,
          //   true,
          //   this.props.frndOnChat.group ? 0 : 1
          // );
        setTimeout(() => {
          this.props.handleSendTyping(this.props.frndOnChat.group ? this.props.frndOnChat.group.token: this.props.frndOnChat.token, this.props.frndOnChat.group ? 0 : 1, false );

          // this.props.TypingAction(this.props.frndOnChat.group? this.props.frndOnChat.group.token
          //     : this.props.frndOnChat.token,
          //   false,
          //   this.props.frndOnChat.group ? 0 : 1
          // );
        }, 3000);
      }
    });

    window.addEventListener("keydown", (e) => {
      var key = e.keyCode || e.which;
      if (key === 13 && !this.state[16]) {
        e.preventDefault();
        if (!this.state.message && !this.state.attachment) return;

        let credencials = {
          receiver: this.props.frndOnChat.group
            ? this.props.frndOnChat.group.id
            : this.props.frndOnChat.id,
          message: this.state.message,
          type: this.props.frndOnChat.group ? 0 : 1,
          attachment: this.state.attachment,
        };
        if (this.state.message && this.state.replyChat) {
          credencials.reply_id = this.state.replyChat.id;
          credencials.reply_data =
            this.state.replyChat.type === "text"
              ? this.state.replyChat.body
              : this.state.replyChat.file.name;
          this.handleReplyMessage(credencials);
          return this.setState({ replyChat: null, message: "" });
        }
        if (this.state.attachment) {
          this.props.SendFilesTofrnd(credencials);
        }
        if (this.state.message && !this.state.attachment) {
          this.props.SendMessageToFrnd(credencials);
        }
        return this.setState({
          message: "",
          attachment: null,
          rows: 1,
          16: false,
        });
      }
      if (key === 16) {
        this.setState({ 16: true });
      }
      if (key === 13 && this.state[16]) {
        this.setState({ rows: this.state.rows++, 16: false });
      }
    });
    window.addEventListener("keyup", (e) => {
      e.preventDefault();
      this.setState({ 16: false });
    });
  }
  handleActiveImage(id){
    this.setState({active_image: id})
  }
  removefile(id){
    let a = [...this.state.attachment]
     a = a.filter((d , index)=>{
      if(parseInt(id) !== index ){
        return d
      }
    })
    this.setState({attachment: a.length? a : null})
  }
  handleSetMessage(e) {
    e.preventDefault();
    this.props.handleSendTyping(this.props.frndOnChat.group ? this.props.frndOnChat.group.token: this.props.frndOnChat.token, this.props.frndOnChat.group ? 0 : 1);
    // this.props.TypingAction(this.props.frndOnChat.group ? this.props.frndOnChat.group.token: this.props.frndOnChat.token, true, this.props.frndOnChat.group ? 0 : 1);
    return this.setState({ message: e.target.value });
  }
  handleSetAttachment(e) {
    e.preventDefault();
    let attachment = [];
    if(this.state.attachment){
      attachment = [...this.state.attachment]
    }
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let element = files[i];
      attachment.push(element)
    }
    if(e.target.files.length){
      let url = URL.createObjectURL(e.target.files[0])
      // if()
      // let img = window.document.getElementById('image-preview') 
      // img.src = url
      // img.style.display = "block"
    }
    this.setState({ attachment });
  }
  handleSendMessage(e) {
    e.preventDefault();
    if (!this.state.message && !this.state.attachment) return;

    let credencials = {
      receiver: this.props.frndOnChat.group
        ? this.props.frndOnChat.group.id
        : this.props.frndOnChat.id,
      message: this.state.message,
      type: this.props.frndOnChat.group ? 0 : 1,
      attachment: this.state.attachment,
    };
    if (this.state.message && this.state.replyChat) {
      credencials.reply_id = this.state.replyChat.id;
      credencials.reply_data =
        this.state.replyChat.type === "text"
          ? this.state.replyChat.body
          : this.state.replyChat.file.name;
      this.handleReplyMessage(credencials);
      return this.setState({ replyChat: null, message: "" });
    }
    if (this.state.attachment) {
      this.props.SendFilesTofrnd(credencials);
    }
    if (this.state.message && !this.state.attachment) {
      this.props.SendMessageToFrnd(credencials);
    }
    return this.setState({ message: "", attachment: null });
  }

  handleReplyMessage(data) {
    return this.props.SendReplyMessageToFrnd(data);
  }

  handlesetReplymessage(data) {
    this.setState({ replyChat: data });
  }

  handleDeleteBulkMessageForMe(obj, del_type) {
    this.props.DeleteBulkMessageforMe(obj, del_type);
  }
  handleSetActiveChatNull(){
    // e.preventDefault()
    this.props.setActiveChatNull()
  }
  render() {
    return (
      <div className="ca-content">
        <Message
          handleSetMessage={this.handleSetMessage}
          {...this.state}
          user={this.props.user}
          handleSendMessage={this.handleSendMessage}
          handleSetAttachment={this.handleSetAttachment}
          frndOnChat={this.props.frndOnChat}
          chats={this.props.chats}
          handlesetReplymessage={this.handlesetReplymessage}
          handleDeleteBulkMessageForMe={this.handleDeleteBulkMessageForMe}
          handleSetActiveChatNull={this.handleSetActiveChatNull}
          friends={this.props.friends}
          handleActiveImage={this.handleActiveImage}
          removefile={this.removefile}
          chat_status={this.props.chat_status}
        />
        <CallTap />
        <ContactTab />
      </div>
    );
  }
}
 


const mapStateToProps = state => {
    return {
      chats: state.friends.chat ? [...state.friends.chat] : [],
      user: state.user.user ? { ...state.user.user } : null,
      chat_status: {...state.friends.chat_status},
    };
}

export default connect(mapStateToProps, {
  SendMessageToFrnd,
  SendFilesTofrnd,
  SendReplyMessageToFrnd,
  TypingAction,
  DeleteBulkMessageforMe,
  setActiveChatNull,
})(ChatSideContainer);