import React from 'react'

export default function ViewProfileModal(props) {
    return (
      <div
        className="modal profile-dialog"
        id="viewProfileModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="viewProfileModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="back2homepage" data-dismiss="modal">
                <i className="mdi mdi-arrow-left"></i>
                <span>Back</span>
              </div>
              <div className="profile-modal-close" data-dismiss="modal">
                <i className="mdi mdi-close"></i>
              </div>
            </div>
            <div className="modal-body p-0">
              {/* was removed by George */}
              {/* style="background-image: url(./assets/images/media/07.jpg);" */}
              <div className="ca-profile-thumb">
                <div className="ca-profile-info">
                  <div className="ca-profile-pic">
                    <img
                      className="img-fluid"
                      src="./assets/images/user/500/01.jpg"
                      alt=""
                    />
                  </div>
                  <div className="ca-profile-title">
                    <h2 className="ca-profile-name">{props.user? props.user.fname: ''} {props.user? props.user.lname: ''}</h2>
                  </div>
                </div>
                <div className="ca-profile-actions">
                  <div className="iconbox-group">
                    <div className="iconbox btn-hovered-light">
                      <i className="iconbox__icon mdi mdi-pencil-outline"></i>
                    </div>
                  </div>
                </div>
                <div className="ca-overlay"></div>
              </div>

              <div className="profile-settings-list">
                <div className="setting-sunheading pl-2">
                  Personal & Contact Information
                </div>
                <ul className="list-unstyled">
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-gender-male-female"></i>
                        </div>
                        <div className="ps-list__left--name">Gender</div>
                      </div>
                      <div className="ps-list__right">
                        <span>Female</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-cake"></i>
                        </div>
                        <div className="ps-list__left--name">Birth date</div>
                      </div>
                      <div className="ps-list__right">20/12/2016</div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-cellphone-android"></i>
                        </div>
                        <div className="ps-list__left--name">Mobile number</div>
                      </div>
                      <div className="ps-list__right">+01 369 2015</div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-email-outline"></i>
                        </div>
                        <div className="ps-list__left--name">Email</div>
                      </div>
                      <div className="ps-list__right">
                        {props.user? props.user.email : ''}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-at"></i>
                        </div>
                        <div className="ps-list__left--name">Website</div>
                      </div>
                      <div className="ps-list__right">
                        www.earnestclements.com
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="setting-sunheading pl-2 py-4">
                  Social Network
                </div>
                <ul className="list-unstyled">
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-facebook"></i>
                        </div>
                        <div className="ps-list__left--name">Facebook</div>
                      </div>
                      <div className="ps-list__right">earnest.clements</div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-instagram"></i>
                        </div>
                        <div className="ps-list__left--name">Instagram</div>
                      </div>
                      <div className="ps-list__right">earnest_clements</div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-snapchat"></i>
                        </div>
                        <div className="ps-list__left--name">Snapchat</div>
                      </div>
                      <div className="ps-list__right">earnestclements212</div>
                    </div>
                  </li>
                  <li>
                    <div className="ps-list">
                      <div className="ps-list__left">
                        <div className="ps-list__left--icon">
                          <i className="mdi mdi-twitter"></i>
                        </div>
                        <div className="ps-list__left--name">Twitter</div>
                      </div>
                      <div className="ps-list__right">iamearnest</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
