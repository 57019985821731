import {
  SET_GROUP,
  MESSAGE_SENT_GROUP,
  SET_ACTIVE_GROUP_CHAT,
  GROUP_CREATED,
  GROUP_LAST_CHAT,
  LOAD_GROUP_MEMBERS,
  REMOVE_USERS
} from "../types.js";

let initialState = {
    groups: null,
    groupActive: null
}



function SortGroup(arr){
	var currentGroup;
    for(var i = 1; i < arr.length; i++){
        currentGroup = arr[i];
        for(var j = i - 1; j >= 0 ; j--) {
          var currentGroupdate = new Date(currentGroup.last_chat && currentGroup.last_chat && currentGroup.last_chat.chat_time?currentGroup.last_chat.chat_time: "1999-10-01T00:00:00.000Z");
          var arrGroupdate = new Date(currentGroup.last_chat && arr[j].last_chat && arr[j].last_chat.chat_time?arr[j].last_chat.chat_time:"1999-10-01T00:00:00.000Z" );
          if (parseInt(arrGroupdate.getTime() ) > parseInt(currentGroupdate.getTime())) {
            break;
          }
            arr[j+1] = arr[j]
        }
        arr[j + 1] = currentGroup;
    }
    return [...arr];
}

const groupsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_GROUP:
        let groups = SortGroup(payload);
        return { ...state, groups };
      case GROUP_CREATED:
        let newGroups = SortGroup([...state.groups ,{...payload}])
        return {...state, groups:newGroups}
      case SET_ACTIVE_GROUP_CHAT:
        for (let i = 0; i < state.groups.length; i++) {
          let group = state.groups[i];
          if (group.group.id === payload.group_id ) {
            group.chats = payload.chats;
            if (payload.has_next_page === 'true') {
              group.page = group.page ? group.page + 1 : 2;
            }else{
              group.page = 0
            }
            break
          }
        }
        return {...state}
      case MESSAGE_SENT_GROUP:
        if (!state.groups) return {...state}
        for (let i = 0; i < state.groups.length; i++) {
          let group = state.groups[i];
          if (group.group.id === payload.id) {
            let lastDay = group.chats ? group.chats[group.chats.length - 1] : null;
            if (lastDay && lastDay.date === payload.message.created_at.split(" ")[0]) {
              group.chats[group.chats.length - 1].chat.push(payload.message);
            } else {
              let newChatDay = {
                date: payload.message.created_at.split(" ")[0],
                chat: [payload.message],
              };
              group.chats  && group.chats.push(newChatDay)
            }
            group.last_chat = payload.message
            break;
          }
        }
        let sortedGroup = SortGroup(state.groups) 
        return { ...state, groups: sortedGroup};

      case GROUP_LAST_CHAT:
        if (!state.groups) return {...state}
        for (let i = 0; i < state.groups.length; i++) {
          let group = state.groups[i];
          if (group.group.id === payload.id) {
            group.last_chat = payload.message
            break;
          }
        }
        let newSortedGroup = SortGroup(state.groups) 
        return { ...state, groups: newSortedGroup };

      case LOAD_GROUP_MEMBERS:
        for (let i = 0; i < state.groups.length; i++) {
          let group = state.groups[i];
          if (group.group.id === payload.id){
            if (group.group_members) {
              group.group_members = { ...group.group_members , ...payload.message};
            }else {
              group.group_members = payload.message
            }
            break
          }
          // return {...state}
        }
      case REMOVE_USERS:
        for (let i = 0; i < state.groups.length; i++) {
          let group = state.groups[i];
          if (group.group.id === payload.id) {
            if (group.group_members) {
              let m = { ...group.group_members};
              let o = Object.keys(m).map((data)=>{
                if (payload.message[data]){
                  delete m[data]
                }
                return data
              })
              group.group_members = m
            } 
            break
          }
        }
        return {...state}
      default:
        return state;
    }
}

export default groupsReducer;
