import React, { Component } from 'react'
import {connect} from 'react-redux';
import CallList from './CallList'
import ChatList from './ChatList'
import ContactList from './ContactList'
import {
  SetActiveChat,
  sortFrnd,
  messageRead,
  Logout,
} from "./../../../action/utils.js";

class ChatSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submenu: 'closed',
            chats: [],
            friends: [],
            groups: [],
            friendsObj: {}

        };
        this.handleToggleSubMenu = this.handleToggleSubMenu.bind(this)
    };
    componentDidMount(){
      let obj = {}
      for (let i = 0; i < this.props.friends.length; i++) {
        let frnd = this.props.friends[i];
        if (frnd.fname && obj[frnd.fname[0].toUpperCase()  ]) {
          obj[frnd.fname[0].toUpperCase()].push(frnd);
        }else{
          obj[frnd.fname[0].toUpperCase()] = [];
          obj[frnd.fname[0].toUpperCase()].push(frnd); 
        }
      }
        this.setState({
          friends: this.props.friends,
          groups: this.props.groups,
          user: this.props.user,
          friendsObj: obj,
        });
    };
   
    // componentDidUpdate(prevProps, prevState){
    //   console.log(this.props.friends.length, prevProps.friends.length);
    //   if (prevProps.friends.length !== this.props.friends.length) {
    //     // console.log("lllll", prevProps.friends.length);
    //     return this.setState({
    //       friends: this.props.friends,
    //       groups: this.props.groups,
    //       user: this.props.user,
    //     });
    //   }
    // }
    handleToggleSubMenu () {
      this.setState({submenu: this.state.submenu === 'closed'? 'open': 'closed'})
    }
    handleSelectFrnd = (frnd, type) => {
    return this.props.SetActiveChat(frnd, type );
    }
    handleMessageRead = (id ,chat_id=false, group_id=false) =>{
      return this.props.messageRead(id, chat_id, group_id)
    }
  render() {
    return (
      <div className="ca-sidebar-wrapper" style={{backgroundColor: 'white'}}>
        <div className="ca-sidebar">
          <div className="ca-sidebar__header">
            <div
              className="ca-userprofile"
              data-toggle="modal"
              data-target="#viewProfileModal"
            >
              <a href="" onClick={(e)=>{e.preventDefault();}}  className="user-avatar user-avatar-rounded p-2 bg-light">
                <h6 className='m-auto'>{this.props.user?this.props.user.fname[0].toUpperCase(): ''} {this.props.user? this.props.user.lname[0].toUpperCase(): ''}</h6>
                {/* <img src="./assets/images/user/100/face-01.jpg" alt="" /> */}
              </a>
            </div>
            <div className="iconbox-group">
              <div className="iconbox iconbox-search btn-hovered-light">
                <i className="iconbox__icon mdi mdi-magnify"></i>
              </div>

              <div className="iconbox btn-hovered-light">
                <i className="iconbox__icon mdi mdi-bell-outline"></i>
              </div>

              <div className="iconbox-dropdown dropdown">
                <div
                  className="iconbox btn-hovered-light"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="iconbox__icon mdi mdi-dots-horizontal"></i>
                </div>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="" onClick={(e)=>{e.preventDefault();}}>
                    <span>
                      <i className="mdi mdi-star-outline"></i>
                    </span>
                    <span>Starred Messages</span>
                  </a>
                  <a
                    className="dropdown-item"
                    href=""
                    data-toggle="modal"
                    data-target="#invitePeopleModal"
                    onClick={(e)=>{e.preventDefault();}}
                  >
                    <span>
                      <i className="mdi mdi-account-multiple-plus-outline"></i>
                    </span>
                    <span>Invite People</span>
                  </a>

                  <a
                    className="dropdown-item"
                    href=""
                    data-toggle="modal"
                    data-target="#settingsModalCenter"
                    onClick={(e)=>{e.preventDefault();}} 
                  >
                    <span>
                      <i className="mdi mdi-settings-outline"></i>
                    </span>
                    <span>Settings</span>
                  </a>

                  <a className="dropdown-item" href="" onClick={(e)=>{e.preventDefault();}}>
                    <span>
                      <i className="mdi mdi-help-circle-outline"></i>
                    </span>
                    <span>Help</span>
                  </a>
                  <a className="dropdown-item" href="" onClick={(e)=>{e.preventDefault();}}>
                    <span>
                      <i className="mdi mdi-comment-quote-outline"></i>
                    </span>
                    <span>Feedback</span>
                  </a>
                  <a className="dropdown-item" href="" onClick={(e)=>{e.preventDefault();}}>
                    <span>
                      <i className="mdi mdi-information-outline"></i>
                    </span>
                    <span>About us</span>
                  </a>
                  <a className="dropdown-item" href="" onClick={(e)=>{e.preventDefault();this.props.Logout()}}>
                    <span>
                      <i className="mdi mdi-information-outline"></i>
                    </span>
                    <span>Logout</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="iconbox-searchbar">
              <form>
                <input
                  type="text"
                  className="form-control"
                  id="userSearch"
                  placeholder="Search here..."
                  autoFocus
                />

                <button className="search-submit" type="submit">
                  <i className="mdi mdi-magnify"></i>
                </button>
                <a href="" onClick={(e)=>{e.preventDefault();}} className="search-close">
                  <i className="mdi mdi-arrow-left"></i>
                </a>
              </form>
            </div>
          </div>

          <div className="ca-sidebar__body">
            <div className="ca-navigation-tabs">
              <div className="nav-style-1">
                <ul className="nav" id="caMainTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="caChatsTab"
                      data-toggle="pill"
                      href="#caChats"
                      role="tab"
                      aria-controls="caChats"
                      aria-selected="true"
                    >
                      <span className="mdi mdi-message-text"></span>
                      <span>Chats</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="caCallsTab"
                      data-toggle="pill"
                      href="#caCalls"
                      role="tab"
                      aria-controls="caCalls"
                      aria-selected="false"
                    >
                      <span className="mdi mdi-phone"></span>
                      <span>Calls</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="caContactsTab"
                      data-toggle="pill"
                      href="#caContacts"
                      role="tab"
                      aria-controls="caContacts"
                      aria-selected="false"
                    >
                      <span className="mdi mdi-account-box-outline"></span>
                      <span>Contacts</span>
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="caMainTabContent">
                  {/* Personal chat */}
                  <ChatList
                    {...this.state}
                    friends= {this.props.friends.filter((data)=>{
                      if (data.last_chat.id  && data.last_chat.id) {
                        return data;
                      } 
                    })}
                    groups = {this.props.groups}
                    user= {this.props.user}
                    frndOnChat={this.props.frndOnChat}
                    handleSelectFrnd = {this.handleSelectFrnd}
                    messageRead = {this.handleMessageRead}
                  />
                  {/* Personal chat */}
                  {/* Call  */}
                  <CallList />
                  {/* Call  List*/}
                  {/* Contant List */}
                  <ContactList friendsObj={{...this.state.friendsObj}} />
                  {/* Contant List */}
                </div>
              </div>

              <ul
                id="mfbMenu"
                className="mfb-component--br mfb-slidein"
                data-mfb-toggle="click"
                data-mfb-state={this.state.submenu}
              >
                <li className="mfb-component__wrap">
                  <a href="#" className="mfb-component__button--main">
                    <i onClick={this.handleToggleSubMenu} className="mfb-component__main-icon--resting mdi mdi-plus ion-plus-round"></i>
                    <i onClick={this.handleToggleSubMenu}  className="mfb-component__main-icon--active mdi mdi-close ion-close-round"></i>
                  </a>
                  <ul className="mfb-component__list d-block">
                    <li>
                      <a
                        href=""
                        data-toggle="modal"
                        data-target="#themeModal"
                        data-mfb-label="Theme"
                        className="mfb-component__button--child"
                        onClick={(e)=>{e.preventDefault();}} 
                      >
                        <i className="mfb-component__child-icon mdi mdi-palette"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        onClick={(e)=>{e.preventDefault();}}
                        data-toggle="modal"
                        data-target="#newGroupModal"
                        data-mfb-label="Create Group"
                        className="mfb-component__button--child"
                      >
                        <i className="mfb-component__child-icon mdi mdi-account-group"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        href=""
                        onClick={(e)=>{e.preventDefault();}}
                        data-toggle="modal"
                        data-target="#newCallModal"
                        data-mfb-label="New Call"
                        className="mfb-component__button--child"
                      >
                        <i className="mfb-component__child-icon mdi mdi-phone"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        href=""
                        onClick={(e)=>{e.preventDefault();}}
                        data-toggle="modal"
                        data-target="#newMsgModal"
                        data-mfb-label="New Chat"
                        className="mfb-component__button--child"
                      >
                        <i className="mfb-component__child-icon mdi mdi-android-messages"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    groups: state.groups.groups ? [...state.groups.groups] : [],
    user: state.user.user ? { ...state.user.user } : null,
    frndOnChat: state.friends.frndOnChat
      ? { ...state.friends.frndOnChat }
      : null,
  };
};



export default connect(mapStateToProps, { SetActiveChat, messageRead, Logout })(
  ChatSideBar
);