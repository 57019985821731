import {combineReducers} from 'redux';
import authReducer from './authReducer.js'
import frndsReducer from './friends.js';
import groupsReducer from './groups.js';


const reducers = combineReducers({
    user: authReducer,
    friends: frndsReducer,
    groups: groupsReducer
})
export default reducers