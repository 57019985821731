import React from 'react'

export default function ContactTab() {
    return (
      <div className="ca-content__contactstab">
        <div className="container">
          <div className="conversation-panel__back-button back2contacts">
            <i className="mdi mdi-arrow-left"></i>
            <span>Back</span>
          </div>
          {/* this was remove by George */}
          {/* style={background-image: url(./assets/images/media/07.jpg);} */}
          <div className="ca-profile-thumb">
            <div className="ca-profile-info">
              <div className="ca-profile-pic">
                <img
                  className="img-fluid"
                  src="./assets/images/user/500/09.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="ca-profile-actions">
              <div className="iconbox-group">
                <div className="iconbox btn-hovered-light">
                  <i className="iconbox__icon mdi mdi-star-outline"></i>
                </div>
                <div className="iconbox btn-hovered-light">
                  <i className="iconbox__icon mdi mdi-pencil-outline"></i>
                </div>
                <div className="iconbox btn-hovered-light">
                  <i className="iconbox__icon mdi mdi-dots-horizontal"></i>
                </div>
              </div>
            </div>

            <div className="ca-overlay"></div>
          </div>

          <div className="profile-settings-list border mb-5">
            <div className="ca-profile-title">
              <h2 className="ca-profile-name">Pearl Villarreal</h2>
              <div className="ca-profile-tag">
                <span>Co-Workers</span>
              </div>
              <div className="ca-contcat-profile__calling-buttons pt-2">
                <div className="iconbox iconbox-pill btn-outline-light">
                  <i className="iconbox__icon mdi mdi-message-text-outline"></i>
                  <span>Message</span>
                </div>
                <div className="iconbox iconbox-pill btn-outline-light">
                  <i className="iconbox__icon mdi mdi-phone"></i>
                  <span>Voice Call</span>
                </div>
                <div className="iconbox iconbox-pill btn-outline-light">
                  <i className="iconbox__icon mdi mdi-video-outline"></i>
                  <span>Video Call</span>
                </div>
              </div>
            </div>

            <div className="setting-sunheading  setting-sunheading-style01  my-3">
              Personal & Contact Information
            </div>
            <ul className="list-unstyled">
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-account-heart-outline"></i>
                    </div>
                    <div className="ps-list__left--name">Nick name</div>
                  </div>
                  <div className="ps-list__right">Eva</div>
                </div>
              </li>
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-gender-male-female"></i>
                    </div>
                    <div className="ps-list__left--name">Gender</div>
                  </div>
                  <div className="ps-list__right">Female</div>
                </div>
              </li>
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-cake"></i>
                    </div>
                    <div className="ps-list__left--name">Birth date</div>
                  </div>
                  <div className="ps-list__right">20/12/2016</div>
                </div>
              </li>
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-cellphone-android"></i>
                    </div>
                    <div className="ps-list__left--name">Mobile number</div>
                  </div>
                  <div className="ps-list__right">+01 369 2015</div>
                </div>
              </li>
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-email-outline"></i>
                    </div>
                    <div className="ps-list__left--name">Email</div>
                  </div>
                  <div className="ps-list__right">
                    earnest.clements@email.com
                  </div>
                </div>
              </li>
              <li>
                <div className="ps-list border-bottom-0">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-at"></i>
                    </div>
                    <div className="ps-list__left--name">Website</div>
                  </div>
                  <div className="ps-list__right">www.earnestclements.com</div>
                </div>
              </li>
            </ul>

            <div className="setting-sunheading setting-sunheading-style01 my-4">
              Social Network
            </div>

            <ul className="list-unstyled">
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-facebook"></i>
                    </div>
                    <div className="ps-list__left--name">Facebook</div>
                  </div>
                  <div className="ps-list__right">earnest.clements</div>
                </div>
              </li>
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-instagram"></i>
                    </div>
                    <div className="ps-list__left--name">Instagram</div>
                  </div>
                  <div className="ps-list__right">earnest_clements</div>
                </div>
              </li>
              <li>
                <div className="ps-list">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-snapchat"></i>
                    </div>
                    <div className="ps-list__left--name">Snapchat</div>
                  </div>
                  <div className="ps-list__right">earnestclements212</div>
                </div>
              </li>
              <li>
                <div className="ps-list border-bottom-0">
                  <div className="ps-list__left">
                    <div className="ps-list__left--icon">
                      <i className="mdi mdi-twitter"></i>
                    </div>
                    <div className="ps-list__left--name">Twitter</div>
                  </div>
                  <div className="ps-list__right">iamearnest</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
}
